import { useParams } from 'react-router-dom';
import { useQuote } from 'shared/queries';
import { EditQuote } from './edit';

export function EditQuoteResolver() {
    const { id } = useParams<{ id: string }>();
    const { data, isFetching } = useQuote(id || '');
    if (!data || isFetching) {
        return null;
    }
    return <EditQuote quote={data} />;
}
