import { getAppPermissions, useUser } from '@insight2profit/drive-app';
import { Box, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { SwitchGroup } from 'shared/components/forms/controls';
import { DIVISION_OPTIONS, PERMISSIONS } from 'shared/constants';
import { IProfileConfiguration } from 'shared/types';
import { getDivisionByPermission } from 'shared/utility';

export function DivisionGroupSwitch() {
    const user = useUser();
    const permissions = getAppPermissions(user.insightClaims);
    const { watch, setValue } = useFormContext<IProfileConfiguration>();

    const swapDivision = useCallback(
        (key: string) => {
            const divisionsSelected = watch('divisionEnabled') as string[];
            if (divisionsSelected.some(d => d === key)) {
                setValue('divisionEnabled', [...divisionsSelected.filter(d => d !== key)]);
            } else {
                if (
                    watch('erpEnabled') === PERMISSIONS.radius &&
                    (key === PERMISSIONS.wands || divisionsSelected.some(d => d === PERMISSIONS.wands))
                ) {
                    setValue('divisionEnabled', []);
                }
                setValue('divisionEnabled', [...watch('divisionEnabled'), key]);
            }
        },
        [setValue, watch]
    );

    return (
        <Box display={'flex'} pt={2} flexDirection={'column'}>
            <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                Division
            </Typography>
            {DIVISION_OPTIONS.filter(d => permissions.includes(d.key as `mcc.${string}`)).length > 1 ? (
                <Box pt={1}>
                    <SwitchGroup
                        multiple
                        options={DIVISION_OPTIONS.filter(d => permissions.includes(d.key as `mcc.${string}`))}
                        checkedValue={watch('divisionEnabled')}
                        handleChange={swapDivision}
                    />
                </Box>
            ) : (
                <Typography variant='subtitle1'>
                    You have access to <b>{getDivisionByPermission(watch('divisionEnabled')[0])}</b>
                </Typography>
            )}
        </Box>
    );
}
