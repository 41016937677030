import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { IProductLookup } from 'shared/types';

interface IUseSelectFormProductProps {
    index?: number;
}

export function useSelectFormProduct(props?: IUseSelectFormProductProps) {
    const index = props?.index;

    const { setValue, clearErrors } = useFormContext();

    const updateField = useCallback(
        (field: `${string}`, value: any) => {
            const prefix = `products.${index}`;
            const key = `${prefix}.${field}` as const;
            setValue(index !== undefined ? key : field, value);
        },
        [index, setValue]
    );

    const onChange = useCallback(
        (product: string | IProductLookup | null, typeSelect: 'name' | 'number') => {
            if (typeof product === 'string') {
            } else if (product && product.inputValue) {
                // This is a new product
                updateField('isNew', true);
                updateField('name', typeSelect === 'name' ? product.inputValue : product.productname);
                updateField('number', typeSelect === 'number' ? product.inputValue : product.productnumber);
                updateField('estimatedAnnualQuantity', product.estimatedannualquantity);
                updateField('decoratingTechnology', product.decoratingtechnology);
                updateField('printingTechnology', product.printingtechnology);
                updateField('recommendedPlantCode', product.plantcode);
                updateField('productId', product.itemkey);
                //updateField('endMarket', product.marketdesc);
                clearErrors('products');
            } else {
                updateField('isNew', false);
                updateField('name', product?.productname);
                updateField('number', product?.productnumber);
                updateField('estimatedAnnualQuantity', product?.estimatedannualquantity);
                updateField('decoratingTechnology', product?.decoratingtechnology);
                updateField('printingTechnology', product?.printingtechnology);
                updateField('recommendedPlantCode', product?.plantcode);
                updateField('productId', product?.itemkey);
                //updateField('endMarket', product?.marketdesc);
                clearErrors();
            }
        },
        [updateField, clearErrors]
    );
    const onClear = useCallback(() => {
        updateField('isNew', false);
        updateField('name', '');
        updateField('number', '');
        updateField('decoratingTechnology', '');
        updateField('estimatedAnnualQuantity', '');
        updateField('printingTechnology', '');
        updateField('recommendedPlant', '');
        updateField('productId', '');
        //updateField('endMarket', '');
    }, [updateField]);

    return [onChange, onClear] as const;
}
