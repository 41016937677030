import { Analytics, ContentCopy, NavigateBefore, Task } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { CustomerFields } from '../shared/customer/customer-fields';
import { DealInfoFields } from '../shared/deal-info/deal-info-fields';
import { ProductFields } from '../shared/product/fields';
import { useCallback, useState } from 'react';
import { IQuote, IQuoteExcelExport, ProductForm, QuoteStatusEnum } from 'shared/types';
import { useFormContext } from 'react-hook-form';
import { getExcelExportData } from 'shared/utility';
import { useExcelExport } from 'shared/mutations';
import { EXPORT_QUOTE_ID } from 'shared/constants';
import { QUOTE_FORM_STYLES } from '../styles';
import { MarginVariance } from '../new/marginVariance';
import { DealLevelSummary } from '../shared/deal-level/summary';
import { ExportQuoteButton } from '../shared/export-quote-button';
import { SubmitNotesFields, ReviewNotesFields } from '../shared/notes';
import { SaveQuoteButtons } from '../shared/save-quote-buttons';

interface IEditQuoteFormProps {
    quote: IQuote;
    user: app.UserInfo | null;
    cancel: () => void;
    navigateToNewQuoteCopyPage: (quoteToCopy: IQuote) => void;
    setIsSaveOnly: (isSaveOnly: boolean) => void;
}

export function EditQuoteForm({ quote, cancel, user, navigateToNewQuoteCopyPage, setIsSaveOnly }: IEditQuoteFormProps) {
    const { setValue, watch } = useFormContext<IQuote>();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const excelMutation = useExcelExport<IQuoteExcelExport>();

    const status = watch('status') as QuoteStatusEnum;
    const watchProducts = watch('products') as ProductForm[];
    const watchQuoteNumber = watch('quoteNumber') as number;
    const showCompleteButton = !watchProducts.some(p => p.finalMargin && Number(p.finalMargin) < 0.25);

    const saveQuote = useCallback(() => {
        setValue('revisedBy', (user?.displayName || '').toUpperCase());
        setValue('revisedOn', new Date().toISOString());
    }, [setValue, user]);

    const submitQuote = useCallback(() => {
        setValue('status', QuoteStatusEnum.SUBMITTED);
        setIsSaveOnly(false);
        saveQuote();
    }, [setValue, setIsSaveOnly, saveQuote]);

    const completeQuote = useCallback(() => {
        setValue('status', QuoteStatusEnum.COMPLETED);

        setIsSaveOnly(false);
        saveQuote();
    }, [setValue, setIsSaveOnly, saveQuote]);

    const updateQuote = useCallback(() => {
        if (status === QuoteStatusEnum.DRAFT) {
            setValue('status', QuoteStatusEnum.IN_PROCESS);
        }
        setIsSaveOnly(true);
        saveQuote();
    }, [status, setValue, setIsSaveOnly, saveQuote]);

    const exportQuote = useCallback(async () => {
        try {
            const data: IQuoteExcelExport = getExcelExportData(quote);
            await excelMutation.mutateAsync({
                exportId: EXPORT_QUOTE_ID,
                data,
                fileName: `${data.customerName} - ${quote.quoteNumber}`,
            });
        } catch (e) {
            console.error(e);
        }
    }, [quote, excelMutation]);

    return (
        <>
            <Grid container justifyContent='space-between' gap={3} sx={QUOTE_FORM_STYLES.formTopWrapper}>
                <Grid item container justifyContent={'space-between'}>
                    <Grid item flex={'1'}>
                        <Button startIcon={<NavigateBefore />} onClick={cancel} variant='outlined'>
                            Go back
                        </Button>
                    </Grid>
                    <Grid item container justifyContent='flex-end' gap={3} flex={'3'}>
                        <ExportQuoteButton onClick={exportQuote} />

                        <SaveQuoteButtons
                            showSaveButton={quote?.status !== QuoteStatusEnum.COMPLETED}
                            onSaveButtonClick={updateQuote}
                            showSubmitButton={
                                (quote?.status === QuoteStatusEnum.DRAFT ||
                                    quote?.status === QuoteStatusEnum.IN_PROCESS) &&
                                !showCompleteButton
                            }
                            onSubmitButtonClick={submitQuote}
                        />
                        {(quote?.status === QuoteStatusEnum.SUBMITTED ||
                            ((quote?.status === QuoteStatusEnum.DRAFT ||
                                quote?.status === QuoteStatusEnum.IN_PROCESS) &&
                                showCompleteButton)) && (
                            <Grid item>
                                <Button startIcon={<Task />} type='submit' variant='contained' onClick={completeQuote}>
                                    Complete Quote
                                </Button>
                            </Grid>
                        )}
                        {quote?.status !== QuoteStatusEnum.IN_PROCESS && (
                            <Grid item>
                                <Button
                                    startIcon={<ContentCopy />}
                                    onClick={() => navigateToNewQuoteCopyPage(quote)}
                                    variant='outlined'
                                >
                                    Copy Quote
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={QUOTE_FORM_STYLES.formWrapper}>
                <Box display='flex' justifyContent={'space-between'}>
                    <Box width='33%'>
                        <Typography variant='h5' sx={QUOTE_FORM_STYLES.formLeftTitle}>
                            Quote ID: {watchQuoteNumber || 'N/A'}
                            <Button variant='text' onClick={() => setDialogOpen(true)}>
                                <Analytics />
                            </Button>
                        </Typography>
                    </Box>
                    <Box width='33%'>
                        <Typography variant='h5' sx={QUOTE_FORM_STYLES.formCenterTitle}>
                            Edit Quote
                        </Typography>
                    </Box>
                    <Box width='33%'></Box>
                </Box>
                <Box sx={QUOTE_FORM_STYLES.formContent}>
                    <Box>
                        <DealLevelSummary />
                    </Box>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid xs={12} md={6} item>
                                <CustomerFields />
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <DealInfoFields />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <ProductFields />
                    </Box>
                    <Box>
                        <SubmitNotesFields />
                    </Box>
                    <Box>
                        <ReviewNotesFields />
                    </Box>
                    <Box>
                        <Box sx={QUOTE_FORM_STYLES.formBottomActions}>
                            <Grid item container justifyContent='flex-end' gap={3}>
                                {quote?.status !== QuoteStatusEnum.COMPLETED && (
                                    <Grid item flex={'1'} display={'flex'}>
                                        <Button startIcon={<NavigateBefore />} onClick={cancel} variant='outlined'>
                                            Go back
                                        </Button>
                                    </Grid>
                                )}
                                <SaveQuoteButtons
                                    showSaveButton={quote?.status !== QuoteStatusEnum.COMPLETED}
                                    onSaveButtonClick={updateQuote}
                                    showSubmitButton={
                                        (quote?.status === QuoteStatusEnum.DRAFT ||
                                            quote?.status === QuoteStatusEnum.IN_PROCESS) &&
                                        !showCompleteButton
                                    }
                                    onSubmitButtonClick={submitQuote}
                                />
                                {(quote?.status === QuoteStatusEnum.SUBMITTED ||
                                    ((quote?.status === QuoteStatusEnum.DRAFT ||
                                        quote?.status === QuoteStatusEnum.IN_PROCESS) &&
                                        showCompleteButton)) && (
                                    <Grid item>
                                        <Button
                                            startIcon={<Task />}
                                            type='submit'
                                            variant='contained'
                                            onClick={completeQuote}
                                        >
                                            Complete Quote
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </Box>
                    <MarginVariance dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
                </Box>
            </Box>
        </>
    );
}
