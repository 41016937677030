import { ICustomerLookup } from 'shared/types';
import { useSnackbar } from 'notistack';
import { useService } from '@insight2profit/drive-app';
import { useQuery } from '@tanstack/react-query';

export function useCustomerSearch(
    queryKey: string,
    query: string,
    user: app.UserInfo | null,
    isRadius: boolean,
    isWandS: boolean
) {
    const { quoteService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const initialData: ICustomerLookup[] = [];
    const { data, isFetching, isLoading } = useQuery<ICustomerLookup[], Error>({
        queryKey: [queryKey, query],
        queryFn: () => quoteService.getCustomerData(query, user, isRadius, isWandS),
        initialData,
        enabled: Boolean(query),
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            return initialData;
        },
    });

    return { data, isFetching, isLoading } as const;
}
