import { I2PEnvironment } from '@price-for-profit/micro-services';
import { Product } from './backend';

export type ProductForm = Required<
    Pick<
        Product,
        | 'productId'
        | 'isNew'
        | 'name'
        | 'number'
        | 'brand'
        | 'estimatedAnnualQuantity'
        | 'estimatedCostPerUnit'
        | 'similarityToCustomersExistingLabel'
        | 'labelComplexity'
        | 'endMarket'
        | 'baselineMargin'
        | 'baselineKey'
        | 'recommendedPlant'
        | 'businessUnit'
        | 'recommendedPlantCode'
        | 'decoratingTechnology'
        | 'substrate'
        | 'printingTechnology'
        | 'peerGroup'
        | 'peerGroupPercentile'
        | 'targetMargin'
        | 'floorMargin'
        | 'finalMargin'
        | 'revisedPricePerUnit'
        | 'uniqueId'
    >
> & { productKey?: string; isReviewed?: ReviewedEnum };

export type TProductFormWithId = ProductForm & Record<'id', string>;

export interface ProductSummaryForm {
    products: ProductForm[];
}

export type TContractOrSpotBuy = 'CONTRACT' | 'SPOT-BUY' | 'OFF-CONTRACT-SPOT-BUY';
export type TSimilarityToCustomersExistingLabelKey = 'LOW-TO-MODERATE' | 'HIGH';
export type TSimilarityToCustomersExistingLabelValue = 'LOW TO MODERATE' | 'HIGH';
export type TLabelComplexityKey = 'TCS-MACHINE' | 'LOW' | 'MEDIUM' | 'HIGH' | 'BLANK LABEL';
export type TLabelComplexityValue = 'TCS MACHINE' | 'LOW' | 'MEDIUM' | 'HIGH' | 'BLANK LABEL';
export type TCompetitivenessKey = 'LOW' | 'MEDIUM' | 'HIGH';
export type TCompetitivenessValue = 'LOW' | 'MEDIUM' | 'HIGH';
export type TBusinessTypeKey = 'NEW' | 'EXISTING';
export type TShareOfCustomersLabelSpendKey =
    | '100%-FOR-SEVERAL-YEARS'
    | '100%-OF-BUSINESS-SPEND'
    | 'MAJORITY-OF-BUSINESS'
    | 'MINORITY-OF-BUSINESS'
    | 'NONE';
export type TShareOfCustomersLabelSpendValue =
    | '100% FOR SEVERAL YEARS'
    | '100% OF BUSINESS SPEND'
    | 'MAJORITY OF BUSINESS'
    | 'MINORITY OF BUSINESS'
    | 'NONE';

export type TTier = 'tier 1' | 'tier 2' | 'tier 3' | 'tier 4' | 'tier 5';

export enum QuoteStatusEnum {
    SUBMITTED = 'SUBMITTED',
    DRAFT = 'DRAFT',
    IN_PROCESS = 'IN PROCESS',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    COMPLETED = 'COMPLETED',
    DELETED = 'DELETED',
}

export enum DivisionEnum {
    WANDS = 'W&S',
    FANDB = 'F&B',
    HPC = 'HPC',
}

export type TDivisionKeys = keyof typeof DivisionEnum;

export enum ERPEnum {
    RADIUS = 'RADIUS',
    GLOBETEK = 'GLOBE-TEK',
}

export interface IQuote {
    id: string;
    customerName: string;
    customerNameOverride?: string;
    businessUnit: string;
    tier: string;
    endMarket?: string; // temp key, would be removed in the future
    achievableSpend: string;
    newOrExisting: TBusinessTypeKey | '';
    salesRepName: string;
    contractOrSpotBuy: TContractOrSpotBuy | '';
    competitiveness: TCompetitivenessKey | '';
    shareOfCustomersLabelSpend: TShareOfCustomersLabelSpendKey | '';
    quoteSizeAdder: string;
    date: string;
    quoteDescription: string;
    products: ProductForm[];
    createdBy: string;
    createdOn: string;
    revisedOn: string;
    revisedBy: string;
    erp: string;
    division: string;
    status: QuoteStatusEnum;
    reviewedByNotes: string;
    submittedByNotes: string;
    recommendedAnnualRevenue?: number;
    revisedAnnualRevenue?: number;
    deltaAnnualRevenue?: number;
    quoteNumber?: number;
    isSavedAsDraft?: boolean;
    keyCompetitor: string;
    brand?: string;
    mostUsedBrand?: string;
}
export interface QuoteSizeAddder {
    adder: number;
    displayname: string;
    uniqueid: string;
    row_order: number;
}

export type TQuoteWithoutId = Omit<IQuote, 'id'>;

export type MassActionSelectionType = 'floor' | 'target' | 'override';

export interface MassActionSelection {
    type: MassActionSelectionType;
    display: string;
}

export interface MassActionForm {
    type: MassActionSelection | null;
    change: number | string;
}

export interface ISendApprovalNotificationProps {
    environment: I2PEnvironment;
    division: TDivisionKeys;
    quoteId: string;
    quoteNumber: string;
    customerName: string;
    username: string;
}

export interface IDownloadFileProps<T> {
    exportId: string;
    fileName?: string;
    orientation?: 'landscape' | 'portrait';
    data: T;
}

export enum ReviewedEnum {
    YES = 'YES',
    NO = 'NO',
}

export interface ISetProductKeysProps {
    productToSetKey: ProductForm;
    products: ProductForm[];
    quoteNumber: number;
    index?: number;
}
