import { useI2pServerDataGrid } from '@price-for-profit/data-grid';
import { useNavigate } from 'react-router-dom';
import { QUERY_KEYS } from 'shared/constants';
import { usePermissionERPDivisionState, useQuoteDeleteDialogState } from 'shared/contexts';
import { IQuote } from 'shared/types';
import { getQuotesColumns } from './columns';
import { useService, useUser } from '@insight2profit/drive-app';
import { QueryKey } from '@tanstack/react-query';
import { Quote } from './quote';

export function QuoteResolver() {
    // Check for additional permissions or any logic before rendering a page
    const { quoteService } = useService();
    const navigate = useNavigate();
    const user = useUser();
    const { divisionEnabled } = usePermissionERPDivisionState();

    const navigateToEditPage = (quoteId: string) => {
        navigate(`/quote/edit/${quoteId}`);
    };

    const { toggleDeleteQuoteDialog, setQuoteToDelete, setQuotesQueryKey } = useQuoteDeleteDialogState();

    const handleDeleteClicked = (quote: IQuote, queryKey: QueryKey) => {
        setQuotesQueryKey(queryKey);
        setQuoteToDelete(quote);
        toggleDeleteQuoteDialog();
    };

    const navigateToNewQuoteCopyPage = (quote: IQuote) => {
        navigate(`/quote/new`, {
            state: {
                quoteToCopy: quote,
            },
        });
    };

    const columns = getQuotesColumns({
        navigateToEditPage,
        navigateToNewQuoteCopyPage,
        isPricingLeadership: user?.isPricingLeadership || divisionEnabled.length > 1,
        handleDeleteClicked,
    });

    const { getDataGridProps } = useI2pServerDataGrid<IQuote>({
        columns,
        name: QUERY_KEYS.quote,
        getData: async state => {
            return quoteService.getGridData(state, columns, user?.isPricingLeadership || divisionEnabled.length > 1);
        },
        initialState: {
            sortModel: [{ field: 'date', sort: 'desc' }],
        },
    });

    const dataGridProps = getDataGridProps();

    return <Quote dataGridProps={dataGridProps} />;
}
