import { Dialog, DialogContent, DialogTitle, Tab, Tabs, Box, DialogContentText } from '@mui/material';
import { useState } from 'react';
import { ProductHistogramChart, ProductScatterChart } from 'shared/components/charts';
import { TabPanel } from 'shared/components/tabs';
import { ProductForm, TProductFormWithId } from 'shared/types';
import { PRODUCT_DASHBOARD_MODAL_STYLES } from '../styles';

interface DashboardModalProps {
    open: boolean;
    product: TProductFormWithId | null;
    quoteProducts: ProductForm[];
    onClose: () => void;
}

export function DashboardModal({ open, product, quoteProducts, onClose }: DashboardModalProps) {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Dialog open={open} onClose={onClose} maxWidth={'lg'} fullWidth>
            <DialogTitle>Revenue and Margin %</DialogTitle>
            <DialogContent>
                <DialogContentText sx={PRODUCT_DASHBOARD_MODAL_STYLES.subtitleStyle}>
                    {product?.peerGroup}
                </DialogContentText>
                {product && (
                    <>
                        <Box sx={PRODUCT_DASHBOARD_MODAL_STYLES.tabHeaderStyle}>
                            <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                                <Tab label='Scatter' />
                                <Tab label='Histogram' />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <ProductScatterChart product={product} quoteProducts={quoteProducts} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <ProductHistogramChart product={product} />
                        </TabPanel>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
}
