import { ManageAccounts } from '@mui/icons-material';
import { Divider, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routing';

export function UserMenu() {
    const navigate = useNavigate();

    const goToProfileConfig = () => {
        document.getElementById('user-menu-button')?.click();
        navigate(ROUTES.profileConfiguration);
    };

    return (
        <>
            <MenuItem onClick={goToProfileConfig}>
                <ListItemIcon>
                    <ManageAccounts />
                </ListItemIcon>
                <ListItemText primary={'Profile Configuration'} />
            </MenuItem>
            <Divider />
        </>
    );
}
