import { useNavigate } from 'react-router-dom';
import { AppForm } from 'shared/components/forms';
import { AppConfig, IQuote, QuoteStatusEnum } from 'shared/types';
import { ROUTES } from 'routing';
import {
    calculateRecommendedAnnualRevenue,
    calculateRevisedAnnualRevenue,
    formatObjectValuesToUppercase,
    getDivisionByPermission,
    getDivisionEnumKey,
    getERPByPermission,
} from 'shared/utility';
import { useSendApprovalQuoteNotification, useUpdateQuote } from 'shared/mutations';
import { EditQuoteForm } from './editQuoteForm';
import { useCallback, useState } from 'react';
import { useTitle, useUser, useService } from '@insight2profit/drive-app';
import { usePermissionERPDivisionState } from 'shared/contexts';

interface IEditQuoteProps {
    quote: IQuote;
}

export function EditQuote({ quote }: IEditQuoteProps) {
    useTitle(`MCC - Edit Quote`);
    const user = useUser();
    const navigate = useNavigate();
    const { appConfig } = useService<AppConfig>();
    const environment = appConfig.environment;
    const { divisionEnabled, erpEnabled } = usePermissionERPDivisionState();

    const [isSaveOnly, setIsSaveOnly] = useState<boolean>(false); // updating quote without changing status

    const updateQuoteMutation = useUpdateQuote();
    const sendApprovalQuoteNotification = useSendApprovalQuoteNotification();

    const navigateToNewQuoteCopyPage = useCallback(
        (quoteToCopy: IQuote) => {
            navigate(`/quote/new`, {
                state: {
                    quoteToCopy,
                },
            });
        },
        [navigate]
    );

    const cancel = useCallback(() => {
        navigate(`/quote`);
    }, [navigate]);

    const submit = async (data: IQuote) => {
        try {
            //make the form data in uppercase
            formatObjectValuesToUppercase(data);
            const revisedAnnualRevenue = calculateRevisedAnnualRevenue(
                data.products,
                getERPByPermission(erpEnabled),
                getDivisionByPermission(divisionEnabled[0])
            );
            const recommendedAnnualRevenue = calculateRecommendedAnnualRevenue(
                data.products,
                getERPByPermission(erpEnabled),
                getDivisionByPermission(divisionEnabled[0])
            );
            const brandMap = data.products
                .map(({ brand }) => brand)
                .filter(brand => brand)
                .reduce(
                    (map: Map<string, number>, brand: string): Map<string, number> =>
                        map.set(brand, map.has(brand) ? map.get(brand)! + 1 : 1),
                    new Map()
                );
            const brand = Array.from(brandMap.keys()).join(',');
            let mostUsedBrandValue = 0;
            let mostUsedBrandKey = '';
            brandMap.forEach((value: number, key: string) => {
                if (value >= mostUsedBrandValue) {
                    mostUsedBrandValue = value;
                    mostUsedBrandKey = key;
                }
            });

            const updatedQuote = {
                ...data,
                brand,
                mostUsedBrand: mostUsedBrandKey,
                id: data.id.toLowerCase(),
                revisedAnnualRevenue: revisedAnnualRevenue,
                recommendedAnnualRevenue: recommendedAnnualRevenue,
                deltaAnnualRevenue: revisedAnnualRevenue - recommendedAnnualRevenue,
            };
            await updateQuoteMutation.mutateAsync(updatedQuote);

            if (data.status === QuoteStatusEnum.SUBMITTED && !isSaveOnly) {
                await sendApprovalQuoteNotification.mutateAsync({
                    environment,
                    quoteId: updatedQuote.id,
                    quoteNumber: updatedQuote.quoteNumber!.toString(),
                    customerName: updatedQuote.customerName,
                    division: getDivisionEnumKey(updatedQuote.division),
                    username: user?.email || '',
                });
                navigate(ROUTES.quote);
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            <AppForm id='edit-quote-form' defaultForm={{ ...quote }} onSubmit={submit}>
                <EditQuoteForm
                    quote={quote}
                    cancel={cancel}
                    user={user}
                    navigateToNewQuoteCopyPage={navigateToNewQuoteCopyPage}
                    setIsSaveOnly={setIsSaveOnly}
                />
            </AppForm>
        </>
    );
}
