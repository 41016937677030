import { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from 'routing';
import { AppForm } from 'shared/components/forms';
import { QUOTE_NUMBER_SEED } from 'shared/constants';
import { useCreateQuote, useSendApprovalQuoteNotification, useUpdateQuote } from 'shared/mutations';
import { AppConfig, IQuote, QuoteStatusEnum, TQuoteWithoutId } from 'shared/types';
import {
    calculateRecommendedAnnualRevenue,
    calculateRevisedAnnualRevenue,
    formatObjectValuesToUppercase,
    getDivisionByPermission,
    getDivisionEnumKey,
    getERPByPermission,
    initializeProductKeys,
} from 'shared/utility';
import { v4 as uuid } from 'uuid';
import { NewQuoteForm } from './newQuoteForm';
import { useTitle, useService, useUser } from '@insight2profit/drive-app';
import { usePermissionERPDivisionState } from 'shared/contexts';

const DEFAULT_FORM: IQuote = {
    id: '',
    customerName: '',
    tier: '',
    achievableSpend: '',
    businessUnit: '',
    newOrExisting: '',
    salesRepName: '',
    keyCompetitor: '',
    contractOrSpotBuy: 'SPOT-BUY',
    competitiveness: '',
    shareOfCustomersLabelSpend: '',
    quoteSizeAdder: '',
    date: new Date().toISOString(),
    quoteDescription: '',
    products: [],
    createdBy: '',
    createdOn: '',
    revisedOn: '',
    revisedBy: '',
    erp: '',
    division: '',
    status: QuoteStatusEnum.DRAFT,
    reviewedByNotes: '',
    submittedByNotes: '',
    isSavedAsDraft: true, // we remove this key when the user creates the quote
};

export function NewQuote() {
    useTitle(`MCC - New Quote`);
    const { quoteService } = useService();
    const { state } = useLocation();
    const navigate = useNavigate();
    const user = useUser();
    const { divisionEnabled, erpEnabled } = usePermissionERPDivisionState();
    const { appConfig } = useService<AppConfig>();
    const environment = appConfig.environment;

    const createQuoteMutation = useCreateQuote();
    const updateQuoteMutation = useUpdateQuote(undefined, false);
    const sendApprovalQuoteNotification = useSendApprovalQuoteNotification();

    const quoteToCopy: IQuote = useMemo(() => state?.quoteToCopy, [state]);
    const isQuoteToCopy = useMemo(() => !!quoteToCopy, [quoteToCopy]);
    const cancel = useCallback(() => {
        navigate(`/quote`);
    }, [navigate]);

    const quoteForm: IQuote = useMemo(
        () =>
            isQuoteToCopy
                ? {
                      ...DEFAULT_FORM,
                      customerName: quoteToCopy!.customerName,
                      tier: quoteToCopy!.tier,
                      achievableSpend: quoteToCopy!.achievableSpend,
                      businessUnit: quoteToCopy!.businessUnit,
                      newOrExisting: quoteToCopy!.newOrExisting,
                      salesRepName: quoteToCopy!.salesRepName,
                      keyCompetitor: quoteToCopy!.keyCompetitor,
                      contractOrSpotBuy: quoteToCopy!.contractOrSpotBuy,
                      competitiveness: quoteToCopy!.competitiveness,
                      shareOfCustomersLabelSpend: quoteToCopy!.shareOfCustomersLabelSpend,
                      quoteSizeAdder: quoteToCopy!.quoteSizeAdder,
                      quoteDescription: quoteToCopy!.quoteDescription,
                      erp: quoteToCopy!.erp,
                      division: quoteToCopy!.division,
                      customerNameOverride: quoteToCopy!.customerNameOverride,
                      products: quoteToCopy!.products.map(p => {
                          return {
                              ...p,
                              baselineMargin: '',
                              targetMargin: '',
                              floorMargin: '',
                              finalMargin: '',
                              uniqueId: uuid(),
                              productKey: '',
                          };
                      }),
                  }
                : DEFAULT_FORM,
        [isQuoteToCopy, quoteToCopy]
    );

    const sendEmailNotification = useCallback(
        async (quote: IQuote) => {
            try {
                if (quote.status === QuoteStatusEnum.SUBMITTED) {
                    await sendApprovalQuoteNotification.mutateAsync({
                        environment,
                        quoteId: quote.id,
                        quoteNumber: quote.quoteNumber!.toString(),
                        customerName: quote.customerName,
                        division: getDivisionEnumKey(quote.division),
                        username: user?.email || '',
                    });
                }
            } catch (e) {
                console.error(e);
            }
        },
        [user, environment, sendApprovalQuoteNotification]
    );

    const redirectAfterSave = useCallback(
        ({ id, status }: IQuote) => {
            if (status !== QuoteStatusEnum.SUBMITTED && id) {
                navigate(`/quote/edit/${id}`);
                return;
            }
            navigate(ROUTES.quote);
        },
        [navigate]
    );

    const submit = async (data: IQuote) => {
        try {
            //make the form data in uppercase
            formatObjectValuesToUppercase(data, ['id']);
            const revisedAnnualRevenue = calculateRevisedAnnualRevenue(
                data.products,
                getERPByPermission(erpEnabled),
                getDivisionByPermission(divisionEnabled[0])
            );
            const recommendedAnnualRevenue = calculateRecommendedAnnualRevenue(
                data.products,
                getERPByPermission(erpEnabled),
                getDivisionByPermission(divisionEnabled[0])
            );

            const brandMap = data.products
                .map(({ brand }) => brand)
                .filter(brand => brand)
                .reduce(
                    (map: Map<string, number>, brand: string): Map<string, number> =>
                        map.set(brand, map.has(brand) ? map.get(brand)! + 1 : 1),
                    new Map()
                );
            const brand = Array.from(brandMap.keys()).join(',');
            let mostUsedBrandValue = 0;
            let mostUsedBrandKey = '';
            brandMap.forEach((value: number, key: string) => {
                if (value >= mostUsedBrandValue) {
                    mostUsedBrandValue = value;
                    mostUsedBrandKey = key;
                }
            });

            const newQuote: TQuoteWithoutId = {
                ...data,
                createdBy: user?.displayName.toUpperCase() || '',
                createdOn: new Date().toISOString(),
                revisedOn: '',
                revisedBy: '',
                erp: getERPByPermission(erpEnabled),
                division: getDivisionByPermission(divisionEnabled[0]),
                reviewedByNotes: '',
                revisedAnnualRevenue,
                recommendedAnnualRevenue,
                deltaAnnualRevenue: revisedAnnualRevenue - recommendedAnnualRevenue,
                brand,
                mostUsedBrand: mostUsedBrandKey,
            };
            delete newQuote.isSavedAsDraft; // removed cuz were setting to inProcess/Submitted

            // we don't need to create a quote cuz its already been created hitting calculate margin button
            if (data.id) {
                const quote: IQuote = { ...newQuote, id: data.id };
                const quoteCreated = await updateQuoteMutation.mutateAsync(quote);

                sendEmailNotification(quoteCreated);
                redirectAfterSave(quoteCreated);
                return;
            }

            const initialQuoteCreated = await createQuoteMutation.mutateAsync(newQuote);
            const recentQuotesResponse = await quoteService.getPreviousQuotes(initialQuoteCreated);
            const newQuoteNumber = QUOTE_NUMBER_SEED + recentQuotesResponse.metadata.totalCount;
            initialQuoteCreated.quoteNumber = newQuoteNumber;

            initializeProductKeys(initialQuoteCreated);

            const quoteCreated = await updateQuoteMutation.mutateAsync(initialQuoteCreated);

            sendEmailNotification(quoteCreated);

            redirectAfterSave(quoteCreated);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <AppForm id='new-quote-form' defaultForm={quoteForm} onSubmit={submit}>
            <NewQuoteForm cancel={cancel} user={user} />
        </AppForm>
    );
}
