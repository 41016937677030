export const DEAL_LEVEL_SUMMARY_STYLES = {
    boxStyle: {
        paddingTop: '0',
        margin: 'auto',
        boxShadow: '0 2px 10px -1px #0003, 0 1px 1px #CCCCCC24, 0 1px 3px #CCCCCC1f',
    },
    headerStyle: {
        background: '#C5C5C5',
        fontWeight: 'bold',
    },
    gridStyle: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 4px',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    contentStyle: {
        background: '#EFEFEF',
        textAlign: 'center',
    },
    textBold: {
        fontWeight: 'bold',
    },
    borderLeftLightGray: {
        borderLeft: '1px solid #DBDBDB',
    },
};
