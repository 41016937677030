import { Add } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-premium';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routing';
import { usePermissionERPDivisionState } from 'shared/contexts';

export function QuoteTableToolbar() {
    const navigate = useNavigate();
    const { divisionEnabled } = usePermissionERPDivisionState();

    const navigateToNewQuote = useCallback(() => {
        navigate(ROUTES.newQuote);
    }, [navigate]);

    return (
        <GridToolbarContainer>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} width='100%'>
                <Box sx={{ paddingTop: 1, paddingLeft: 1 }}>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                </Box>
                {divisionEnabled.length === 1 && (
                    <Box sx={{ paddingTop: 1, paddingLeft: 1, marginRight: '16px!important' }}>
                        <Button onClick={navigateToNewQuote} startIcon={<Add />} color='primary' variant='contained'>
                            New Quote
                        </Button>
                    </Box>
                )}
            </Stack>
        </GridToolbarContainer>
    );
}
