import {
    Autocomplete,
    CircularProgress,
    TextField,
    SxProps,
    Theme,
    AutocompleteRenderOptionState,
    Tooltip,
} from '@mui/material';
import { RegisterOptions, useController } from 'react-hook-form';
interface SelectFieldProps<T> {
    name: `${string}` | `${string}.${string}` | `${string}.${number}`;
    label: string;
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    readOnly?: boolean;
    options: T[];
    onSelect?: (option: T | string | null) => void;
    getOptionLabel(option: T): string;
    getOptionSelected(option: T, value: T): boolean;
    loading?: boolean;
    defaultValue?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    sx?: SxProps<Theme>;
    optionIcon?: JSX.Element;
    optionKey?(option: T): string;
    optionLabel?(option: T): string;
    optionTooltip?(option: T): string;
    renderOption?:
        | ((
              props: React.HTMLAttributes<HTMLLIElement>,
              option: T,
              state: AutocompleteRenderOptionState
          ) => React.ReactNode)
        | undefined;
}

export function SelectInput<T>({
    name,
    label,
    rules,
    readOnly,
    options,
    onSelect,
    getOptionLabel,
    getOptionSelected,
    loading,
    fullWidth = true,
    defaultValue = '',
    disabled,
    sx,
    optionIcon,
    optionKey,
    optionLabel,
    optionTooltip,
    renderOption,
}: SelectFieldProps<T>) {
    const {
        field: { ref, value, onChange },
        fieldState: { error },
    } = useController({ name, defaultValue, rules });

    return (
        <Autocomplete
            ref={ref}
            value={value}
            id={name}
            onChange={(_, value) => {
                if (onSelect) {
                    onSelect(value);
                }
                onChange(value);
            }}
            disabled={disabled}
            options={options}
            sx={sx}
            fullWidth={fullWidth}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            data-testid={`${name}-combobox`}
            renderOption={
                renderOption
                    ? renderOption
                    : optionLabel && optionKey
                    ? (props, option) => {
                          return (
                              <li {...props} key={optionKey(option)} style={{ display: 'flex', flexDirection: 'row' }}>
                                  <span style={{ flex: '90%' }}>{optionLabel(option)}</span>
                                  <span style={{ paddingTop: '5px', color: '#363636' }}>
                                      {optionTooltip ? (
                                          optionTooltip(option) ? (
                                              <Tooltip title={optionTooltip(option)} placement='right-start'>
                                                  {optionIcon || <></>}
                                              </Tooltip>
                                          ) : (
                                              <></>
                                          )
                                      ) : (
                                          optionIcon || <></>
                                      )}
                                  </span>
                              </li>
                          );
                      }
                    : undefined
            }
            renderInput={params => (
                <TextField
                    variant='outlined'
                    {...params}
                    label={`${label + (!!rules?.required ? ' (Required)' : ' (Optional)')}`}
                    error={Boolean(error)}
                    helperText={error?.message}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: loading ? (
                            <CircularProgress color='inherit' size={20} />
                        ) : (
                            params.InputProps.endAdornment
                        ),
                        readOnly,
                    }}
                    InputLabelProps={{ shrink: true }}
                />
            )}
        />
    );
}
