import { useQuery } from '@tanstack/react-query';
import { IKeyCompetitorLookup } from 'shared/types';
import { useSnackbar } from 'notistack';
import { QUERY_KEYS } from 'shared/constants';
import { useService } from '@insight2profit/drive-app';

export function useKeyCompetitorSearch(
    query: string,
    user: app.UserInfo | null,
    isRadius: boolean,
    isFandB: boolean,
    isWandS: boolean
) {
    const { quoteService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const initialData: IKeyCompetitorLookup[] = [];
    const { data, isFetching, isLoading } = useQuery<IKeyCompetitorLookup[], Error>(
        [QUERY_KEYS.competitor, query],
        () => quoteService.getKeyCompetitor(query, user, isRadius, isFandB, isWandS),
        {
            initialData,
            enabled: Boolean(query),
            onError: error => {
                enqueueSnackbar(error.message, { variant: 'error' });
                return initialData;
            },
        }
    );

    return { data, isFetching, isLoading } as const;
}
