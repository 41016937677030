import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { ProductSummaryForm, TBackdropReason, TProductFormWithId } from 'shared/types';
import { defaultProduct } from 'shared/utility';
import { ProductCard } from '../forms/card';
import { PRODUCT_FIELDS_STYLES } from '../styles';

interface ProductAddModalProps {
    open: boolean;
    methods: UseFormReturn<ProductSummaryForm, any>;
    onClose: () => void;
    submitProduct: (form: ProductSummaryForm) => void;
    defaultEndMarketProduct: string;
    productToEdit?: TProductFormWithId;
}

export function ProductAddModal({
    methods,
    open,
    onClose,
    submitProduct,
    defaultEndMarketProduct,
    productToEdit,
}: ProductAddModalProps) {
    const { setValue } = methods;
    const handleOnClose = (event: {}, reason: TBackdropReason) => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    useEffect(() => {
        if (productToEdit) {
            setValue(`products.0`, productToEdit);
        }
    }, [productToEdit, setValue]);

    return (
        <Dialog open={open} onClose={handleOnClose}>
            <DialogTitle>
                <Grid container justifyContent={'space-between'}>
                    <Grid item sx={PRODUCT_FIELDS_STYLES.modalTitleStyle}>
                        {productToEdit ? 'Edit' : 'Add'} Product
                    </Grid>
                    <Grid item>
                        <IconButton onClick={onClose} title='Close modal'>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <FormProvider {...methods}>
                <Box component='form' id='add-product-form' onSubmit={methods.handleSubmit(submitProduct)}>
                    <DialogContent>
                        <ProductCard
                            index={0}
                            product={defaultProduct() as TProductFormWithId}
                            defaultEndMarketProduct={defaultEndMarketProduct}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button
                            form='add-product-form'
                            onClick={methods.handleSubmit(submitProduct)}
                            color='secondary'
                            variant='contained'
                        >
                            {productToEdit ? 'Edit' : 'Add'} Product
                        </Button>
                    </DialogActions>
                </Box>
            </FormProvider>
        </Dialog>
    );
}
