import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { ICustomerLookup } from 'shared/types';
import { formatToTitleCase } from 'shared/utility';

export function useSelectFormCustomer() {
    const { setValue, clearErrors } = useFormContext();

    const onChange = useCallback(
        (customer: string | ICustomerLookup | null) => {
            if (typeof customer === 'string') {
                setValue('achievableSpend', '');
            } else if (customer && customer.inputValue) {
            } else {
                setValue('customerName', formatToTitleCase(customer?.commoncustomername || ''));
                setValue('endMarket', customer?.marketdesc || '');
                //setValue('businessUnit', customer?.division || '');
                setValue(
                    'achievableSpend',
                    customer?.achievablespend && Number(customer?.achievablespend) > 0 ? customer?.achievablespend : ''
                );
                setValue('tier', customer?.customertier || '');
                clearErrors();
            }
        },
        [setValue, clearErrors]
    );
    const onClear = useCallback(() => {
        setValue('customerName', '');
        setValue('tier', '');
        setValue('endMarket', '');
        //setValue('businessUnit', '');
        setValue('achievableSpend', '');
    }, [setValue]);

    return [onChange, onClear] as const;
}
