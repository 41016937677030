import { DATASTORE_DOCUMENT, DATASTORE_LOOKUP, DOCUMENT_TABLES, LOOKUP_TABLES } from 'shared/constants';
import { getData, IDataAccessService, IFilter, Operator } from '@price-for-profit/micro-services';
import {
    IProductLookup,
    IDecoratingTechnology,
    IPrintingTechnology,
    IRecommendedPlant,
    IProductMarginScatter,
    ISubstrate,
    IProductErrorData,
    IProductErrorDataWithoutId,
    IProductMarginHistogram,
    IBrand,
} from 'shared/types';
import {
    getBusinessUnitLookupValueFromBusinessUnitSelect,
    getBusinessUnitQueryWithPossibleQuerySearchParams,
    getNoUserOptionsQuery,
    getPermissionAndBusinessUnitBasedCollectionFilterForDuplicates,
    getPermissionAndBusinessUnitBasedCollectionFilterForUnique,
    getPermissionAndBusinessUnitBasedCollectionFilterWithPossibleQuerySearchParams,
} from 'shared/utility';
import { CLIENT_ID } from '@insight2profit/drive-app';

export interface IProductService {
    getProductsData(
        businessUnit: string,
        user: app.UserInfo | null,
        query: string,
        isRadius: boolean,
        isFandB: boolean,
        isWandS: boolean
    ): Promise<IProductLookup[]>;
    getProductScatterData(peerGroup: string): Promise<IProductMarginScatter[]>;
    getProductHistogramData(peerGroup: string): Promise<IProductMarginHistogram[]>;
    getDecoratingTechnologies(
        businessUnit: string,
        user: app.UserInfo | null,
        isRadius: boolean,
        isFandB: boolean,
        isWandS: boolean
    ): Promise<IDecoratingTechnology[]>;
    getPrintingTechnologies(
        businessUnit: string,
        user: app.UserInfo | null,
        isRadius: boolean,
        isFandB: boolean,
        isWandS: boolean
    ): Promise<IPrintingTechnology[]>;
    getSubstrates(
        businessUnit: string,
        user: app.UserInfo | null,
        isRadius: boolean,
        isFandB: boolean,
        isWandS: boolean
    ): Promise<ISubstrate[]>;
    getBrands(
        businessUnit: string,
        user: app.UserInfo | null,
        isRadius: boolean,
        isFandB: boolean,
        isWandS: boolean
    ): Promise<IBrand[]>;
    getRecommendedPlants(
        businessUnit: string,
        user: app.UserInfo | null,
        isRadius: boolean,
        isWandS: boolean
    ): Promise<IRecommendedPlant[]>;
    createProductErrorData(quote: IProductErrorDataWithoutId): Promise<IProductErrorData>;
}

export class ProductService implements IProductService {
    constructor(private dasService: IDataAccessService) {}

    async getProductsData(
        businessUnit: string,
        user: app.UserInfo | null,
        query: string,
        isRadius: boolean,
        isFandB: boolean,
        isWandS: boolean
    ): Promise<IProductLookup[]> {
        const businessUnitQueryValue = getBusinessUnitLookupValueFromBusinessUnitSelect(businessUnit);

        const collectionFilter: IFilter<IProductLookup> = user
            ? businessUnitQueryValue
                ? getBusinessUnitQueryWithPossibleQuerySearchParams(businessUnitQueryValue, query, user, isRadius)
                : getPermissionAndBusinessUnitBasedCollectionFilterWithPossibleQuerySearchParams(
                      query,
                      user,
                      isRadius,
                      isFandB,
                      isWandS
                  )
            : getNoUserOptionsQuery();

        const { data } = await this.dasService.getCollection<IProductLookup, typeof DATASTORE_LOOKUP>({
            clientId: CLIENT_ID,
            databaseLabel: DATASTORE_LOOKUP,
            tableId: LOOKUP_TABLES.productLookup,
            collectionFilter,
        });
        return data;
    }

    async getDecoratingTechnologies(
        businessUnit: string,
        user: app.UserInfo | null,
        isRadius: boolean,
        isFandB: boolean,
        isWandS: boolean
    ): Promise<IDecoratingTechnology[]> {
        const collectionFilter = getPermissionAndBusinessUnitBasedCollectionFilterForDuplicates(
            businessUnit,
            user,
            isRadius,
            isFandB,
            isWandS
        );

        const data = await getData(page =>
            this.dasService.getCollection<IDecoratingTechnology, typeof DATASTORE_LOOKUP>({
                clientId: CLIENT_ID,
                databaseLabel: DATASTORE_LOOKUP,
                tableId: LOOKUP_TABLES.decTechLookup,
                page: page,
                pageSize: 100,
                collectionFilter,
            })
        );
        return data;
    }

    async getPrintingTechnologies(
        businessUnit: string,
        user: app.UserInfo | null,
        isRadius: boolean,
        isFandB: boolean,
        isWandS: boolean
    ): Promise<IPrintingTechnology[]> {
        const collectionFilter = getPermissionAndBusinessUnitBasedCollectionFilterForDuplicates(
            businessUnit,
            user,
            isRadius,
            isFandB,
            isWandS
        );

        const data = await getData(page =>
            this.dasService.getCollection<IPrintingTechnology, typeof DATASTORE_LOOKUP>({
                clientId: CLIENT_ID,
                databaseLabel: DATASTORE_LOOKUP,
                tableId: LOOKUP_TABLES.printTechLookup,
                page: page,
                pageSize: 100,
                collectionFilter,
            })
        );
        return data;
    }

    async getSubstrates(
        businessUnit: string,
        user: app.UserInfo | null,
        isRadius: boolean,
        isFandB: boolean,
        isWandS: boolean
    ): Promise<ISubstrate[]> {
        const collectionFilter = getPermissionAndBusinessUnitBasedCollectionFilterForDuplicates(
            businessUnit,
            user,
            isRadius,
            isFandB,
            isWandS
        );

        const data = await getData(page =>
            this.dasService.getCollection<ISubstrate, typeof DATASTORE_LOOKUP>({
                clientId: CLIENT_ID,
                databaseLabel: DATASTORE_LOOKUP,
                tableId: LOOKUP_TABLES.substrateLookup,
                page: page,
                pageSize: 100,
                collectionFilter,
            })
        );
        return data;
    }
    async getBrands(
        businessUnit: string,
        user: app.UserInfo | null,
        isRadius: boolean,
        isFandB: boolean,
        isWandS: boolean
    ): Promise<IBrand[]> {
        const collectionFilter = getPermissionAndBusinessUnitBasedCollectionFilterForDuplicates(
            businessUnit,
            user,
            isRadius,
            isFandB,
            isWandS
        );

        const data = await getData(page =>
            this.dasService.getCollection<ISubstrate, typeof DATASTORE_LOOKUP>({
                clientId: CLIENT_ID,
                databaseLabel: DATASTORE_LOOKUP,
                tableId: LOOKUP_TABLES.brandLookup,
                page: page,
                pageSize: 100,
                collectionFilter,
            })
        );
        return data;
    }

    async getRecommendedPlants(
        businessUnit: string,
        user: app.UserInfo | null,
        isRadius: boolean,
        isWandS: boolean
    ): Promise<IRecommendedPlant[]> {
        const collectionFilter = getPermissionAndBusinessUnitBasedCollectionFilterForUnique(
            businessUnit,
            user,
            isRadius,
            isWandS,
            true
        );

        const data = await getData(page =>
            this.dasService.getCollection<IRecommendedPlant, typeof DATASTORE_LOOKUP>({
                clientId: CLIENT_ID,
                databaseLabel: DATASTORE_LOOKUP,
                tableId: LOOKUP_TABLES.recommendPlantLookup,
                page: page,
                pageSize: 100,
                collectionFilter,
            })
        );
        return data;
    }

    async getProductScatterData(peerGroup: string): Promise<IProductMarginScatter[]> {
        const data = await getData(page =>
            this.dasService.getCollection<IProductMarginScatter, typeof DATASTORE_LOOKUP>({
                clientId: CLIENT_ID,
                databaseLabel: DATASTORE_LOOKUP,
                tableId: LOOKUP_TABLES.dmtScatterData,
                page: page,
                pageSize: 100,
                collectionFilter: {
                    property: 'peergroup' as keyof IProductMarginScatter,
                    operator: 'eq' as Operator,
                    value: peerGroup,
                },
            })
        );
        return data;
    }

    async getProductHistogramData(peerGroup: string): Promise<IProductMarginHistogram[]> {
        const data = await getData(page =>
            this.dasService.getCollection<IProductMarginHistogram, typeof DATASTORE_LOOKUP>({
                clientId: CLIENT_ID,
                databaseLabel: DATASTORE_LOOKUP,
                tableId: LOOKUP_TABLES.histogramDataSource,
                page: page,
                pageSize: 100,
                collectionFilter: {
                    property: 'peergroup' as keyof IProductMarginHistogram,
                    operator: 'eq' as Operator,
                    value: peerGroup,
                },
            })
        );
        return data;
    }

    async createProductErrorData(quote: IProductErrorDataWithoutId): Promise<IProductErrorData> {
        const { data } = await this.dasService.addRow<IProductErrorDataWithoutId, typeof DATASTORE_DOCUMENT>({
            clientId: CLIENT_ID,
            databaseLabel: DATASTORE_DOCUMENT,
            tableId: DOCUMENT_TABLES.productErrorData,
            payload: quote,
        });
        return data as IProductErrorData;
    }
}
