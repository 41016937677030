import { useService } from '@insight2profit/drive-app';
import { useSnackbar } from 'notistack';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { IQuote } from 'shared/types';

export function useUpdateQuote(queryKey?: QueryKey, showNotification: boolean = true) {
    const { quoteService } = useService();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const mutation = useMutation<IQuote, Error, IQuote>({
        mutationFn: request => quoteService.updateQuote(request),
        onSuccess: () => {
            if (showNotification) {
                if (queryKey) {
                    queryClient.invalidateQueries(queryKey);
                    enqueueSnackbar('Quote has been deleted', { variant: 'success' });
                } else {
                    enqueueSnackbar('Quote was updated', { variant: 'success' });
                }
            }
        },
        onError: () => {
            if (showNotification) {
                enqueueSnackbar('Quote was not updated', { variant: 'error' });
            }
        },
    });

    return mutation;
}
