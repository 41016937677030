export const QUOTE_FORM_STYLES = {
    formWrapper: {
        maxWidth: '1200px',
        margin: 'auto',
        marginBottom: '25px',
    },
    formCenterTitle: {
        paddingTop: '10px',
        margin: 'auto',
        textAlign: 'center',
    },
    formTopWrapper: {
        maxWidth: '1200px',
        margin: 'auto auto 25px',
        padding: '20px 0 10px',
    },
    formLeftTitle: {
        paddingTop: '10px',
        margin: 'auto',
        textAlign: 'left',
    },
    formContent: {
        display: 'flex',
        flexDirection: 'column',
        pt: 2,
        gap: 2,
    },
    formTopActions: {
        padding: '10px',
        paddingBottom: '0',
    },
    formBottomActions: {
        textAlign: 'right',
        margin: '10px 0 20px 0',
    },
};
