import { ReviewedEnum, TLabelComplexityKey, TSimilarityToCustomersExistingLabelKey } from './form';

export const RADIUS_ERP = 'RADIUS';
export const GLOBETEK_ERP = 'GLOBETEK';
export const WANDS_DIVISION = 'NA W&S';
export const OTHER_DIVISION = 'OTHER';
export const HPC_DIVISION = 'NORTH AMERICA - HPC';
export const FANDB_DIVISION = 'NORTH AMERICA - F&B';
export const WANDS_DIVISION_SHORT = 'W&S';
export const HPC_DIVISION_SHORT = 'HPC';
export const FANDB_DIVISION_SHORT = 'F&B';

export interface IQuoteLookup {
    id: string;
}

export interface Product {
    productId: string;
    isNew?: boolean;
    name: string;
    number: string;
    brand: string;
    productKey?: string;
    estimatedAnnualQuantity: number | string;
    estimatedCostPerUnit: number | string;
    similarityToCustomersExistingLabel: TSimilarityToCustomersExistingLabelKey | '';
    endMarket: string;
    baselineMargin: string;
    labelComplexity: TLabelComplexityKey | '';
    recommendedPlant: string;
    recommendedPlantCode: string;
    decoratingTechnology?: string;
    substrate?: string;
    printingTechnology: string;
    peerGroup: string;
    peerGroupPercentile: string;
    targetMargin: string;
    floorMargin: string;
    finalMargin: string;
    revisedPricePerUnit: string;
    uniqueId: string;
    businessUnit: string;
    baselineKey: string;
    isReviewed?: ReviewedEnum;
}

export interface CSVProduct {
    'Product Name'?: string;
    'Product Number'?: string;
    Brand?: string;
    'Estimated Annual Quantity'?: string;
    'Estimated Cost Per Unit'?: string;
    'Similarity To Customers Existing Label'?: string;
    'End Market'?: string;
    'Label Complexity'?: string;
    'Recommended Plant'?: string;
    'Decorating Technology'?: string;
    Substrate?: string;
    'Printing Technology'?: string;
    'Business Unit'?: string;
}

export type IProductErrorDataWithoutId = Omit<IProductErrorData, 'id'>;

export interface IProductErrorData {
    id: string;
    createdOn: string;
    createdBy: string;
    productsWithErrors: CSVProduct[];
}

export interface IProductLookup extends IDivisionSourceLookup {
    itemkey: string;
    productname: string;
    productnumber: string;
    productlookup: string;
    estimatedannualquantity: number | string;
    plantcode: string;
    decoratingtechnology: string;
    printingtechnology: string;
    inputValue?: string;
    addDisplayLabel?: string;
    marketdesc: string;
}

export interface ICustomerLookup {
    commoncustomername: string;
    customerkey: string;
    customertier: string;
    division: string;
    marketdesc: string;
    achievablespend: number | string;
    uniqueid: string;
    inputValue?: string;
}

export interface ICustomerTierLookup {
    customertier: string;
    uniqueid: string;
}

export interface IDivisionSourceLookup {
    division: string;
    source: string;
}

export interface IEndMarketLookup extends IDivisionSourceLookup {
    displayname: string;
    modelkey: string;
    uniqueid: string;
}

export interface IBusinessUnitLookup extends IDivisionSourceLookup {
    displayname: string;
    modelkey: string;
    uniqueid: string;
}

export interface ISalesRepNameLookup extends IDivisionSourceLookup {
    displayname: string;
    modelkey: string;
    uniqueid: string;
}

export interface IDecoratingTechnology extends IDivisionSourceLookup {
    displayname: string;
    modelkey: string;
    uniqueid: string;
}

export interface IPrintingTechnology extends IDivisionSourceLookup {
    displayname: string;
    modelkey: string;
    uniqueid: string;
}

export interface ISubstrate extends IDivisionSourceLookup {
    displayname: string;
    modelkey: string;
    uniqueid: string;
}

export interface IBrand extends IDivisionSourceLookup {
    displayname: string;
    modelkey: string;
    uniqueid: string;
    inputValue?: string;
}

export interface IRecommendedPlant extends IDivisionSourceLookup {
    plantcode: string;
    plantkey: string;
    plantname: string;
    uniqueid: string;
}

export interface IProductMarginScatter {
    uniqueid: string;
    commoncustomername: string;
    dmtscatterdatakey: string;
    peergroup: string;
    ttminvoicevalue: number;
    wtdmodelpct: number;
}

export interface IProductMarginHistogram {
    uniqueid: string;
    histogramdatasourcekey: string;
    marginpct: string;
    pctofrevenue: number;
    peergroup: string;
    ttmrevenue: number;
}

export interface IRecommendedMarginsPayload {
    customerName: string;
    customerTier: string;
    newOrExisting: string;
    transactionType: string;
    competitiveness: string;
    quoteSize: string;
    labelSpendShare: string;
    lineParams: IRecommendedMarginsLineParamsPayload[];
}

export interface IRecommendedMarginsLineParamsPayload {
    productNumber: string;
    productDescription: string;
    endMarket: string;
    printTech: string;
    decTech?: string;
    substrate?: string;
    plantName: string;
    brand: string;
    labelComplexity?: string;
    existingLabelSimilarity?: string;
    lineId?: number;
    businessUnit: string;
}

export interface IRecommendedMarginsResponse {
    percentileSelection: string;
    marginFloor: string;
    changeCap: string;
    callMarginLineModel: IRecommendedMarginsLineModelResponse[];
}

export interface IRecommendedMarginsLineModelResponse {
    erp: string;
    division: string;
    peerGroup: string;
    baseline: number;
    baselineKey: string;
    totalAdder: number;
    recommendation: number;
    peerGroupPercentile: number;
    recommendationType: string;
    finalMarginFloor: number;
    lineId: 1;
}

export interface IKeyCompetitorLookup {
    uniqueid: string;
    modelkey: string;
    displayname: string;
    division: string;
    source: string;
    inputValue?: string;
}
