import { useCallback } from 'react';
import { FieldValues, UseFieldArrayAppend, UseFieldArrayRemove, useForm } from 'react-hook-form';
import { PRODUCT_KEY_SEED } from 'shared/constants';
import { ISetProductKeysProps, ProductForm, ProductSummaryForm, TProductFormWithId } from 'shared/types';
import { defaultProduct } from 'shared/utility';
import { v4 as uuid } from 'uuid';

export interface ProductFormMethodsProps {
    rows: ProductForm[];
    append: UseFieldArrayAppend<FieldValues, 'products'>;
    remove: UseFieldArrayRemove;
}

export function useProductFormMethods({ rows, append, remove }: ProductFormMethodsProps) {
    const copyProduct = useCallback(
        (productToCopy: TProductFormWithId) => {
            const copiedProduct = { ...productToCopy };
            copiedProduct.id = uuid();
            copiedProduct.uniqueId = uuid();
            copiedProduct.floorMargin = '';
            copiedProduct.targetMargin = '';
            copiedProduct.peerGroupPercentile = '';
            copiedProduct.finalMargin = '';
            copiedProduct.baselineMargin = '';
            copiedProduct.productKey = '';
            append(copiedProduct);
        },
        [append]
    );

    const methods = useForm<ProductSummaryForm>({
        defaultValues: { products: [defaultProduct()] },
    });

    const setProductKey = useCallback(({ productToSetKey, products, quoteNumber, index }: ISetProductKeysProps) => {
        if (!quoteNumber) return;
        if (products.length === 0) {
            productToSetKey.productKey =
                quoteNumber + '-' + (!!index ? index + PRODUCT_KEY_SEED : PRODUCT_KEY_SEED).toString().padStart(4, '0');
            return;
        }
        const maxProductKey: number = Math.max(...products.map(p => Number(p.productKey?.split('-')[1] || 0))); //11111-0001
        productToSetKey.productKey =
            quoteNumber + '-' + (maxProductKey + (!!index ? index + 1 : 1)).toString().padStart(4, '0');
    }, []);

    const resetProductMethods = useCallback(() => {
        methods.reset();
        methods.clearErrors('products');
    }, [methods]);

    const removeProduct = useCallback(
        (item: ProductForm) => {
            remove(rows.findIndex(p => p.uniqueId === item.uniqueId));
        },
        [remove, rows]
    );

    return {
        copyProduct,
        methods,
        removeProduct,
        resetProductMethods,
        setProductKey,
    };
}
