import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { RegisterOptions, useController } from 'react-hook-form';

interface DateInputProps {
    name: string;
    label: string;
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    defaultValue?: string;
    disabled?: boolean;
    placeholder?: string;
    onBlur?: () => void;
}

export function DateInput({ name, label, rules, defaultValue, disabled, placeholder, onBlur }: DateInputProps) {
    const {
        field: { ref, value, onChange },
        fieldState: { error },
    } = useController({
        name,
        defaultValue: defaultValue || new Date(),
        rules,
    });
    return (
        <DatePicker
            value={value}
            onChange={onChange}
            renderInput={params => (
                <TextField
                    id={name}
                    {...params}
                    onBlur={onBlur}
                    fullWidth
                    inputRef={inputRef => {
                        if (params.inputRef && typeof params.inputRef === 'function') {
                            params.inputRef(inputRef);
                        }
                        ref(inputRef);
                    }}
                    label={`${label + (!!rules?.required ? ' (Required)' : ' (Optional)')}`}
                    inputProps={{ ...params.inputProps, 'aria-label': 'Date' }}
                    placeholder={placeholder || label}
                    disabled={disabled}
                    error={Boolean(error)}
                    helperText={error?.message}
                    InputLabelProps={{ shrink: true }}
                />
            )}
            minDate={new Date()}
            mask='__-__-__'
            inputFormat='MM-dd-yy'
        />
    );
}
