import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useQuoteDeleteDialogState } from 'shared/contexts';

interface QuoteDeleteConfirmDialogProps {
    onConfirm: () => void;
}
export function QuoteDeleteConfirmDialog({ onConfirm }: QuoteDeleteConfirmDialogProps) {
    const { deleteDialogOpen, toggleDeleteQuoteDialog, quoteToDelete } = useQuoteDeleteDialogState();
    return (
        <Dialog open={deleteDialogOpen} onClose={toggleDeleteQuoteDialog}>
            <DialogTitle>Are you sure you want to delete quote {quoteToDelete?.quoteNumber}?</DialogTitle>
            <DialogContent>
                This will remove the quote from the application and you will not be able to restore it.
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        toggleDeleteQuoteDialog();
                    }}
                >
                    cancel
                </Button>
                <Button
                    onClick={() => {
                        onConfirm();
                        toggleDeleteQuoteDialog();
                    }}
                    variant='contained'
                >
                    confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
