import { Download } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';

interface IExportQuoteButtonProps {
    isDisabled?: boolean;
    onClick: () => Promise<void>;
}

export function ExportQuoteButton({ isDisabled = false, onClick }: IExportQuoteButtonProps) {
    return (
        <Grid item>
            <Button startIcon={<Download />} type='button' variant='contained' disabled={isDisabled} onClick={onClick}>
                Export Quote
            </Button>
        </Grid>
    );
}
