import { Box, Grid } from '@mui/material';
import { TextInput } from 'shared/components/forms/controls';
import { NOTES_FIELDS_STYLES } from './styles';

export function SubmitNotesFields() {
    return (
        <Box sx={NOTES_FIELDS_STYLES.titleStyles}>
            <Grid xs={12} item>
                <TextInput name={'submittedByNotes'} label='Submitted By Notes' multiline rows={4} />
            </Grid>
        </Box>
    );
}
