import {
    AppLogo,
    AuthenticationRequired,
    LoginLayout,
    LoginPage,
    NavigationLayout,
    UnauthorizedPage,
    useService,
} from '@insight2profit/drive-app';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { ROUTES } from 'routing';
import clientLogo from 'assets/insight-logo.png';
// import loginClientLogoSrc from '../assets/path-to-client-logo.png;
import insightDriveAccountLogo from 'assets/insight-drive-account-logo.svg';
import { QuoteResolver } from 'pages/quote/resolver';
import { NewQuoteResolver } from 'pages/quote/new/resolver';
import { EditQuoteResolver } from 'pages/quote/edit/resolver';
import { Container, Paper } from '@mui/material';
import { UserMenu } from 'shared/components/usermenu';
import { ProfileConfigurationResolver } from 'pages/profileConfiguration';
import { ProvidePermissionERPDivision } from 'shared/contexts';
import { ProvideQuoteState } from 'shared/contexts';

const isProd = process.env.NODE_ENV === 'production';

const headerClientLogo = <AppLogo src={clientLogo} alt='client logo' />;
const loginClientLogo = <AppLogo src={`${process.env.PUBLIC_URL}/favicon-32x32.png`} alt='client login logo' />;
// const loginClientLogo = <AppLogo src={loginClientLogoSrc} alt='insight client login logo' />;
const insightLoginLogo = <AppLogo src={insightDriveAccountLogo} alt='insight logo' maxHeight='30px' />;
const driveToProfitLogo = (
    <AppLogo src={`${process.env.PUBLIC_URL}/DRIVEtoPROFIT_Color.png`} alt='drive to profit logo' maxHeight='50px' />
);

export function AppRoutes() {
    return useRoutes([
        {
            element: <Login />,
            children: [
                {
                    path: ROUTES.login,
                    element: (
                        <LoginPage
                            tabTitle='Mcc quote Login'
                            insightLoginLogo={insightLoginLogo}
                            clientLogo={loginClientLogo}
                            loginRedirectRoute={ROUTES.quote}
                        />
                    ),
                },
            ],
        },
        {
            element: <Private />,
            children: [
                {
                    path: ROUTES.quote,
                    children: [
                        {
                            index: true,
                            element: (
                                <ProvideQuoteState>
                                    <QuoteResolver />
                                </ProvideQuoteState>
                            ),
                        },
                        {
                            path: ROUTES.newQuote,
                            element: <NewQuoteResolver />,
                        },
                        {
                            path: ROUTES.editQuote,
                            element: <EditQuoteResolver />,
                        },
                    ],
                },
                {
                    path: ROUTES.profileConfiguration,
                    children: [{ index: true, element: <ProfileConfigurationResolver /> }],
                },
            ],
        },
        {
            path: ROUTES.unauthorized,
            element: <Unauthorized />,
        },
        {
            path: '*',
            element: <Navigate to={ROUTES.login} replace />,
        },
    ]);
}

function Unauthorized() {
    const {
        appConfig: { serviceBaseUrl },
    } = useService();

    return <UnauthorizedPage logo={headerClientLogo} driveLogo={insightLoginLogo} serviceBaseUrl={serviceBaseUrl} />;
}

function Login() {
    const {
        appConfig: { serviceBaseUrl },
    } = useService();

    return (
        <LoginLayout serviceBaseUrl={serviceBaseUrl}>
            <Outlet />
        </LoginLayout>
    );
}

function Private() {
    const {
        appConfig: { serviceBaseUrl, appId },
    } = useService();
    return (
        <AuthenticationRequired>
            <ProvidePermissionERPDivision>
                <NavigationLayout
                    useLocal={!isProd}
                    applicationId={appId}
                    logo={headerClientLogo}
                    serviceBaseUrl={serviceBaseUrl}
                    footerLogo={driveToProfitLogo}
                    childrenUserMenu={<UserMenu />}
                >
                    <Container sx={{ mt: 3.5 }} maxWidth={false}>
                        <Paper>
                            <Outlet />
                        </Paper>
                    </Container>
                </NavigationLayout>
            </ProvidePermissionERPDivision>
        </AuthenticationRequired>
    );
}
