import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { NumberInput } from 'shared/components/forms/controls';
import { DivisionEnum, ERPEnum, ReviewedEnum } from 'shared/types';

interface IFinalMarginInputGridProps {
    params: GridRenderCellParams<string>;
    erp: string;
    division: string;
    updateIsReviewedOnFinalMarginChange: (index: number, value: ReviewedEnum) => void;
    updateFinalMarginOnRevisedPriceChange: (index: number, finalMargin: string, revisedPrice: string) => void;
}

export function FinalMarginInputGrid({
    erp,
    division,
    params,
    updateIsReviewedOnFinalMarginChange,
    updateFinalMarginOnRevisedPriceChange,
}: IFinalMarginInputGridProps) {
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: any) => {
        apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: newValue });
        updateIsReviewedOnFinalMarginChange(
            apiRef.current.getRowIndex(params.id),
            params.row.targetMargin === newValue ? ReviewedEnum.NO : ReviewedEnum.YES
        );
        let revisedPrice = Number(params.row.estimatedCostPerUnit) / (1 - Number(newValue));
        if (erp === ERPEnum.RADIUS && division === DivisionEnum.WANDS) {
            revisedPrice = Number(params.row.estimatedCostPerUnit) * (1 + Number(newValue));
        }
        updateFinalMarginOnRevisedPriceChange(apiRef.current.getRowIndex(params.id), newValue, revisedPrice.toString());
    };

    return (
        <NumberInput
            label=''
            suffix='%'
            name={`finalMargin` + params.id}
            defaultValue={params.value}
            onChangeValue={handleValueChange}
            percent
            decimalPlaces={2}
        />
    );
}
