import { TextField } from '@mui/material';
import { RegisterOptions, useController } from 'react-hook-form';

type TextInputProps = {
    name: string;
    label: string;
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    readOnly?: boolean;
    defaultValue?: string;
    fullWidth?: boolean;
    multiline?: boolean;
    rows?: number;
    disabled?: boolean;
    placeholder?: string;
    startAdornment?: JSX.Element;
    endAdornment?: JSX.Element;
    onBlur?: () => void;
};

export function TextInput({
    name,
    label,
    rules,
    rows,
    fullWidth = true,
    readOnly = false,
    defaultValue = '',
    multiline = false,
    disabled,
    placeholder,
    startAdornment,
    endAdornment,
    onBlur,
}: TextInputProps) {
    const {
        field: { ref, ...inputProps },
        fieldState: { error },
    } = useController({
        name,
        rules,
        defaultValue,
    });

    return (
        <TextField
            {...inputProps}
            id={name}
            inputRef={ref}
            fullWidth={fullWidth}
            label={`${label + (!!rules?.required ? ' (Required)' : ' (Optional)')}`}
            error={Boolean(error)}
            helperText={error?.message}
            multiline={multiline}
            rows={rows}
            disabled={disabled}
            placeholder={placeholder}
            onBlur={onBlur}
            InputProps={{
                readOnly,
                startAdornment,
                endAdornment,
            }}
            InputLabelProps={{ shrink: true }}
        />
    );
}
