import { useQuery } from '@tanstack/react-query';
import { IProductMarginScatter } from 'shared/types';
import { useSnackbar } from 'notistack';
import { QUERY_KEYS } from 'shared/constants';
import { useService } from '@insight2profit/drive-app';

export function useProductScatterData(peerGroup: string) {
    const { productService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const initialData: IProductMarginScatter[] = [];
    const { data, isFetching, isLoading } = useQuery<IProductMarginScatter[], Error>({
        queryKey: [QUERY_KEYS.productScatter, peerGroup],
        queryFn: () => productService.getProductScatterData(peerGroup),
        initialData,
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            return initialData;
        },
    });

    return { data, isFetching, isLoading } as const;
}
