import { Box, FormHelperText, Typography } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IQuote, ProductForm } from 'shared/types';
import { PRODUCT_FIELDS_STYLES } from './styles';
import { ProductSummary } from './summary';

export function ProductFields() {
    const {
        watch,
        formState: { errors },
    } = useFormContext<IQuote>();

    const { fields, append, remove, update } = useFieldArray({
        name: 'products',
    });
    const watchProducts = watch('products');

    const controlledFields = fields.map((field, index) => ({
        ...field,
        ...watchProducts[index],
    }));

    return (
        <Box>
            <Typography variant='h6' sx={{ paddingTop: '10px', margin: 'auto' }}>
                Products
            </Typography>
            <Box sx={PRODUCT_FIELDS_STYLES.boxStyle}>
                <ProductSummary
                    rows={controlledFields as ProductForm[]}
                    append={append}
                    remove={remove}
                    updateProductRow={update}
                />
            </Box>
            <Box>
                <FormHelperText error>{errors?.products?.message}</FormHelperText>
            </Box>
        </Box>
    );
}
