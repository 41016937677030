import { I2PEnvironment } from '@price-for-profit/micro-services';
import { TApprovalNotificationTemplate, TDivisionKeys } from 'shared/types';

const appUrl = `${window.location.protocol}//${window.location.host}`;

type TDivisionDestination = Record<TDivisionKeys, string[]>;

export const APPROVAL_TO_EMAILS: Record<I2PEnvironment, TDivisionDestination> = {
    model: {
        WANDS: [],
        FANDB: [],
        HPC: [],
    },
    dev: {
        WANDS: ['upstart.alinarez@insight2profit.com', 'rlittlejohn@insight2profit.com'],
        FANDB: ['upstart.alinarez@insight2profit.com', 'rlittlejohn@insight2profit.com'],
        HPC: ['upstart.alinarez@insight2profit.com', 'rlittlejohn@insight2profit.com'],
    },
    sandbox: {
        WANDS: ['upstart.alinarez@insight2profit.com'],
        FANDB: ['upstart.alinarez@insight2profit.com'],
        HPC: ['upstart.alinarez@insight2profit.com'],
    },
    prod: {
        WANDS: ['bill.knopka@mcclabel.com'],
        FANDB: ['Melissa.Jenerjohn@mcclabel.com'],
        HPC: ['terri.blahnik@mcclabel.com', 'jason.rodino@mcclabel.com'],
    },
};

export const APPROVAL_CC_EMAILS: Record<I2PEnvironment, string[]> = {
    model: [],
    dev: ['eaquino.upstart@insight2profit.com'],
    sandbox: ['mlyons@insight2profit.com', 'mbrady@insight2profit.com'],
    prod: [],
};

interface IApprovalEmailTemplateProps {
    quoteId: string;
    quoteNumber: string;
    customerName: string;
    username: string;
}

type TApprovalEmailTemplate = Record<TApprovalNotificationTemplate, { body: string; subject: string }>;

export const APPROVAL_EMAIL_TEMPLATES = ({
    quoteId,
    quoteNumber,
    customerName,
    username,
}: IApprovalEmailTemplateProps): TApprovalEmailTemplate => ({
    requestApproval: {
        subject: `Quote is waiting for approval`,
        body: `
        Quote #${quoteNumber} for ${customerName} submitted by ${username} is waiting for your review.

        Link: ${appUrl}/quote/edit/${quoteId}
    `,
    },
});
