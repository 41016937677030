import { useUser, useDebounce } from '@insight2profit/drive-app';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { QUERY_KEYS } from 'shared/constants';
import { usePermissionERPDivisionState } from 'shared/contexts';
import { useCustomerSearch } from 'shared/queries';
import { DivisionEnum, ERPEnum, ICustomerLookup } from 'shared/types';
import { getDivisionByPermission, getERPByPermission } from 'shared/utility';

export interface CustomerOverrideDropdownProps {
    name: `${string}` | `${string}.${string}` | `${string}.${number}`;
    onSelect: (product: string | ICustomerLookup | null) => void;
    onClear: () => void;
}

export function CustomerOverrideDropdown({ name, onSelect, onClear }: CustomerOverrideDropdownProps) {
    const user = useUser();
    const { divisionEnabled, erpEnabled } = usePermissionERPDivisionState();

    const isRadius = getERPByPermission(erpEnabled) === ERPEnum.RADIUS;
    const isWandS = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.WANDS);

    const { watch } = useFormContext();
    const {
        field: { ref, value, onChange },
        fieldState: { error },
    } = useController({
        name,
    });

    const search = (watch(name) as unknown) as string;
    const debouncedSearch = useDebounce(search, 300);
    const { data, isLoading, isFetching } = useCustomerSearch(
        QUERY_KEYS.customerOverride,
        debouncedSearch,
        user,
        isRadius,
        isWandS
    );
    const loading = isLoading || isFetching;

    return (
        <Autocomplete
            ref={ref}
            value={value}
            id={name}
            onChange={async (_, value, reason) => {
                onSelect(value);
                if (reason === 'clear') {
                    onClear();
                }
            }}
            onInputChange={(_, value, reason) => {
                if (reason === 'clear') {
                    onClear();
                }
                onChange(value);
            }}
            options={data.sort((a, b) => a.commoncustomername.localeCompare(b.commoncustomername)) || []}
            selectOnFocus
            freeSolo
            isOptionEqualToValue={(option, value) =>
                option.commoncustomername === value.commoncustomername ||
                option.customernumber === value.inputValue ||
                option.commoncustomername === value.inputValue
            }
            getOptionLabel={option => (option.commoncustomername || option.inputValue || option).toUpperCase()}
            renderOption={(props, option) => (
                <li {...props} key={option.customerkey}>
                    {option.commoncustomername.toUpperCase()}
                </li>
            )}
            data-testid={`${name}-combobox`}
            renderInput={params => (
                <TextField
                    variant='outlined'
                    {...params}
                    label={'Override Display Customer Name (Optional)'}
                    error={Boolean(error)}
                    helperText={error?.message}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: loading ? (
                            <CircularProgress color='inherit' size={20} />
                        ) : (
                            params.InputProps.endAdornment
                        ),
                    }}
                    InputLabelProps={{ shrink: true }}
                />
            )}
        />
    );
}
