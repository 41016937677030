import { PropsWithChildren } from 'react';

interface DataGridProGroupingHeaderProps {
    backgroundColor?: string;
    columnsToGroup: number;
    title: string;
    titleColor?: string;
    width?: number;
}
export function DataGridProGroupingHeader({
    children,
    backgroundColor,
    columnsToGroup,
    title,
    titleColor,
    width,
}: PropsWithChildren<DataGridProGroupingHeaderProps>) {
    return (
        <div style={{ display: 'flex' }}>
            <div
                style={{
                    backgroundColor: backgroundColor || '',
                    height: '85px',
                    borderLeft: '1px solid #DDD',
                    borderRight: '1px solid #DDD',
                    color: titleColor || '#000',
                    left: 0,
                    position: 'absolute',
                    textAlign: 'center',
                    top: '-2px',
                    width: `${columnsToGroup * (width || 200)}px`,
                }}
            >
                <b>{title}</b>
            </div>
            <div style={{ zIndex: '1', marginTop: '40px' }}>
                <b>{children}</b>
            </div>
        </div>
    );
}
