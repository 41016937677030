import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'shared/constants';
import { useService } from '@insight2profit/drive-app';

export function useSalesRepName(
    businessUnit: string,
    user: app.UserInfo | null,
    isRadius: boolean,
    isFandB: boolean,
    isWandS: boolean
) {
    const { quoteService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const { data, isLoading, isFetching } = useQuery<string[], Error>({
        queryKey: [QUERY_KEYS.salesRepName, businessUnit],
        queryFn: () =>
            quoteService
                .getSalesRepNames(businessUnit, user, isRadius, isFandB, isWandS)
                .then(data => data.map(d => d.displayname)),
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
        },
        retry: false,
    });
    return { data, isLoading, isFetching } as const;
}
