import { useUser, useDebounce } from '@insight2profit/drive-app';
import { Autocomplete, CircularProgress, createFilterOptions, TextField } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { usePermissionERPDivisionState } from 'shared/contexts';
import { useProductSearch } from 'shared/queries';
import { DivisionEnum, ERPEnum, GLOBETEK_ERP, IProductLookup, RADIUS_ERP } from 'shared/types';
import { getDivisionByPermission, getERPByPermission } from 'shared/utility';

export interface ProductDropdownProps {
    name: `${string}` | `${string}.${string}` | `${string}.${number}`;
    onSelect: (product: string | IProductLookup | null) => void;
    onClear: () => void;
    businessUnit: string;
}

export function ProductDropdown({ name, onSelect, onClear, businessUnit }: ProductDropdownProps) {
    const { watch } = useFormContext();
    const user = useUser();
    const { divisionEnabled, erpEnabled } = usePermissionERPDivisionState();

    const isRadius = getERPByPermission(erpEnabled) === ERPEnum.RADIUS;
    const isGlobeTek = getERPByPermission(erpEnabled) === ERPEnum.GLOBETEK;
    const isWandS = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.WANDS);
    const isFandB = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.FANDB);

    const {
        field: { ref, value, onChange },
        fieldState: { error },
    } = useController({
        name,
    });

    const search = (watch(name) as unknown) as string;
    const debouncedSearch = useDebounce(search, 300);
    const { data, isLoading, isFetching } = useProductSearch(
        businessUnit,
        user,
        debouncedSearch,
        isRadius,
        isFandB,
        isWandS
    );
    const loading = isLoading || isFetching;

    const filter = createFilterOptions({
        stringify: (option: Record<keyof IProductLookup, string>) =>
            option.addDisplayLabel || `${option.productnumber.toUpperCase()} - ${option.productname.toUpperCase()}$`,
    });

    return (
        <Autocomplete
            ref={ref}
            value={value}
            id={name}
            onChange={async (_, value, reason) => {
                onSelect(value);
                if (reason === 'clear') {
                    onClear();
                }
            }}
            onInputChange={(_, value, reason) => {
                if (reason === 'clear') {
                    onClear();
                } else if (reason === 'input') {
                    onClear();
                }
                onChange(value);
            }}
            options={data || []}
            selectOnFocus
            freeSolo
            isOptionEqualToValue={(option, value) =>
                option.productname === value.productname || option.productname === value.inputValue
            }
            getOptionLabel={option => (option.productname || option.inputValue || option).toUpperCase()}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                    filtered.push({
                        productname: '',
                        productnumber: '',
                        estimatedannualquantity: '',
                        plantcode: '',
                        decoratingtechnology: '',
                        printingtechnology: '',
                        itemkey: `${params.inputValue}`,
                        inputValue: params.inputValue,
                        addDisplayLabel: `ADD "${params.inputValue}"`,
                        marketdesc: '',
                        productlookup: '',
                        division: businessUnit,
                        source: isGlobeTek ? GLOBETEK_ERP : RADIUS_ERP,
                    });
                }
                return filtered;
            }}
            renderOption={(props, option) => (
                <li {...props} key={option.itemkey}>
                    {option.addDisplayLabel ||
                        `${option.productnumber.toUpperCase()} - ${option.productname.toUpperCase()}`}
                </li>
            )}
            data-testid={`${name}-combobox`}
            renderInput={params => (
                <TextField
                    variant='outlined'
                    {...params}
                    label={`Product ${name.includes('number') ? 'Number' : 'Description'} (Optional)`}
                    error={Boolean(error)}
                    helperText={error?.message}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: loading ? (
                            <CircularProgress color='inherit' size={20} />
                        ) : (
                            params.InputProps.endAdornment
                        ),
                    }}
                    InputLabelProps={{ shrink: true }}
                />
            )}
        />
    );
}
