import { useLocalStorage, useUser } from '@insight2profit/drive-app';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { usePermissions } from 'shared/hooks';

interface PermissionERPDivisionContext {
    divisionEnabled: string[];
    setDivisionEnabled: React.Dispatch<React.SetStateAction<string[]>>;
    erpEnabled: string;
    setERPEnabled: React.Dispatch<React.SetStateAction<string>>;
}

const permissionERPDivisionContext = createContext({} as PermissionERPDivisionContext);

export function ProvidePermissionERPDivision({ children }: PropsWithChildren<{}>) {
    const user = useUser();
    const { permissions } = usePermissions(user.insightClaims);

    const getFirstDivision = useMemo(() => {
        let divisions: string[];
        if (user.isFandB) {
            divisions = ['mcc.fandb'];
        } else if (user.isHPC) {
            divisions = ['mcc.hpc'];
        } else if (user.isWandS) {
            divisions = ['mcc.wands'];
        } else {
            divisions = [''];
        }
        return divisions;
    }, [user]);

    const getFirstERP = useMemo(() => {
        if (user.isGlobeTek) {
            return 'mcc.globetek';
        } else if (user.isRadius) {
            return 'mcc.radius';
        } else {
            return '';
        }
    }, [user]);

    const [divisionEnabled, setDivisionEnabled] = useLocalStorage(
        `division-enabled-${user.id || ''}`,
        getFirstDivision
    );

    const [erpEnabled, setERPEnabled] = useLocalStorage(`erp-enabled-${user.id || ''}`, getFirstERP);

    useEffect(() => {
        if (divisionEnabled.some(d => permissions.every(p => p !== d))) {
            setDivisionEnabled(getFirstDivision);
        }
        if (permissions.every(p => p !== erpEnabled)) {
            setERPEnabled(getFirstERP);
        }
    }, [permissions, getFirstDivision, getFirstERP, divisionEnabled, erpEnabled, setDivisionEnabled, setERPEnabled]);

    return (
        <permissionERPDivisionContext.Provider
            value={{
                divisionEnabled,
                setDivisionEnabled,
                erpEnabled,
                setERPEnabled,
            }}
        >
            {children}
        </permissionERPDivisionContext.Provider>
    );
}

export function usePermissionERPDivisionState() {
    return useContext(permissionERPDivisionContext);
}
