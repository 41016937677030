import { Skeleton } from '@mui/material';
import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useProductHistogramData } from 'shared/queries';
import { TProductFormWithId } from 'shared/types';
import { formatMoneyNoDecimals } from 'shared/utility';
import { graphBuilder } from './graphBuilder';

interface ProductHistogramProps {
    product: TProductFormWithId;
}

export function ProductHistogramChart({ product }: ProductHistogramProps) {
    const { data, isLoading, isFetching } = useProductHistogramData(product.peerGroup);
    const { series, labels, maxRevenue } = useMemo(() => graphBuilder(data || []), [data]);
    const options: ApexCharts.ApexOptions = {
        chart: {
            height: 350,
            type: 'line',
        },
        stroke: {
            width: [0, 4],
        },
        title: {
            text: '',
        },
        tooltip: {
            x: {
                show: false,
            },
        },
        colors: ['#008FFB', '#00E396', '#CCCCCC8F'],
        labels: labels,
        xaxis: {
            type: 'category',
            tooltip: {
                enabled: false,
            },
            title: {
                text: 'Estimated Margin %',
            },
        },
        yaxis: [
            {
                min: 0,
                max: maxRevenue,
                title: {
                    text: 'TTM Invoice Value',
                },
                labels: {
                    formatter: function (value: any) {
                        return formatMoneyNoDecimals(value);
                    },
                },
            },
            {
                min: 0,
                max: 100,
                opposite: true,
                title: {
                    text: '% Revenue Running Total',
                },
                tooltip: {
                    enabled: false,
                },
                labels: {
                    formatter: function (value: any) {
                        return `${value}%`;
                    },
                },
            },
            {
                min: 0,
                max: 100,
                opposite: false,
                show: false,
                title: {
                    text: 'Margin %',
                },
                tooltip: {
                    enabled: false,
                },
                labels: {
                    formatter: function (value: any) {
                        return `${value}%`;
                    },
                },
            },
        ],
    };
    return (
        <>
            {isLoading || isFetching ? (
                <Skeleton variant='rectangular' height={365} />
            ) : (
                <ReactApexChart options={options} series={series} type='line' height={350} />
            )}
        </>
    );
}
