import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { ICustomerLookup } from 'shared/types';
import { formatToTitleCase } from 'shared/utility';

export function useSelectFormCustomerOverride() {
    const { setValue } = useFormContext();

    const onChange = useCallback(
        (customer: string | ICustomerLookup | null) => {
            if (typeof customer === 'string') {
            } else if (customer && customer.inputValue) {
            } else {
                setValue('customerNameOverride', formatToTitleCase(customer?.commoncustomername || ''));
            }
        },
        [setValue]
    );
    const onClear = useCallback(() => {
        setValue('customerNameOverride', '');
    }, [setValue]);

    return [onChange, onClear] as const;
}
