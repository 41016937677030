import { Analytics, ContentCopy, Delete, Edit, Warning } from '@mui/icons-material';
import {
    GridActionsCellItem,
    GridColumnHeaderParams,
    GridColumns,
    GridPreProcessEditCellProps,
    GridRenderCellParams,
    GridRowParams,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium';
import { DataGridProGroupingHeader } from 'shared/components/datagrid/datagrid-pro-grouping-header';
import { LABEL_COMPLEXITIES, SIMILARITIES_TO_CUSTOMERS_EXISTING_LABELS } from 'shared/constants';
import {
    DivisionEnum,
    ERPEnum,
    ProductForm,
    ReviewedEnum,
    TLabelComplexityKey,
    TProductFormWithId,
    TSimilarityToCustomersExistingLabelKey,
} from 'shared/types';
import { formatMoney, formatPercent } from 'shared/utility';
import { DecoratingTechnologyGridSelect } from './forms/decorating-technology-grid-select';
import { EndMarketGridSelect } from './forms/end-market-grid-select';
import { FinalMarginInputGrid } from './forms/final-margin-input-grid';
import { PrintingTechnologyGridSelect } from './forms/printing-technology-grid-select';
import { ProductIdGridDisplay } from './forms/product-id-grid-display';
import { SubstrateGridSelect } from './forms/substrate-grid-select';
import { RevisedPriceInputGrid } from './forms/revised-price-input-grid';
import { IconButton, Tooltip } from '@mui/material';

interface ProductColumnsProps {
    remove: (item: TProductFormWithId) => void;
    openDashboard: (item: TProductFormWithId) => void;
    copyProduct: (productToCopy: TProductFormWithId) => void;
    editProduct: (productToCopy: TProductFormWithId) => void;
    updateIsReviewedOnFinalMarginChange: (index: number, value: ReviewedEnum) => void;
    updateFinalMarginOnRevisedPriceChange: (index: number, finalMargin: string, revisedPrice: string) => void;
    erp: string;
    division: string;
}

export const productColumns = ({
    remove,
    copyProduct,
    editProduct,
    openDashboard,
    updateIsReviewedOnFinalMarginChange,
    updateFinalMarginOnRevisedPriceChange,
    erp,
    division,
}: ProductColumnsProps): GridColumns<ProductForm> => [
    {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        hideable: false,
        headerName: 'Checkbox Selection',
    },
    {
        headerName: 'Actions',
        field: 'select',
        type: 'actions',
        width: 150,
        hide: false,
        filterable: false,
        pinnable: false,
        getActions: (params: GridRowParams) => [
            <GridActionsCellItem
                icon={<Edit titleAccess='Edit' />}
                label='Edit'
                color='primary'
                onClick={() => editProduct(params.row)}
            />,
            <GridActionsCellItem
                icon={<ContentCopy titleAccess='Copy' />}
                label='Copy'
                color='primary'
                onClick={() => copyProduct(params.row)}
            />,
            <GridActionsCellItem icon={<Delete />} label='Delete' color='primary' onClick={() => remove(params.row)} />,
        ],
    },
    {
        headerName: 'Line Reviewed',
        field: 'isReviewed',
        width: 150,
    },
    {
        headerName: 'Product Number',
        field: 'number',
        width: 200,
        renderCell: (params: GridRenderCellParams) => (
            <>
                <GridActionsCellItem
                    disabled={!params.row.peerGroup}
                    icon={<Analytics />}
                    label='View Analytics'
                    onClick={() => openDashboard(params.row)}
                />
                <span>{params.value}</span>
            </>
        ),
    },
    {
        headerName: 'Description',
        field: 'name',
        width: 200,
        valueFormatter: params => params.value.toUpperCase(),
    },
    {
        headerName: 'Annual Quantity',
        field: 'estimatedAnnualQuantity',
        width: 200,
        type: 'number',
        valueGetter: params => Number(params.value),
        valueFormatter: params => (params.value ? params.value.toLocaleString('en-us') : '-'),
    },
    {
        headerName: 'Cost Per Unit',
        field: 'estimatedCostPerUnit',
        width: 200,
        renderCell: params => (params.value ? formatMoney(params.value) : '-'),
    },
    {
        headerName: 'Substrate',
        field: 'substrate',
        width: 200,
        editable: true,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            const hasError = !params.props.value;
            return { ...params.props, error: hasError };
        },
        renderEditCell: params => <SubstrateGridSelect {...params} />,
        valueFormatter: params => (params.value || '').toUpperCase(),
    },
    {
        headerName: 'Dec Tech',
        field: 'decoratingTechnology',
        width: 200,
        editable: true,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            const hasError = !params.props.value;
            return { ...params.props, error: hasError };
        },
        renderEditCell: params => <DecoratingTechnologyGridSelect {...params} />,
        valueFormatter: params => (params.value || '').toUpperCase(),
    },
    {
        headerName: 'Print Tech',
        field: 'printingTechnology',
        width: 200,
        editable: true,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            const hasError = !params.props.value;
            return { ...params.props, error: hasError };
        },
        renderEditCell: params => <PrintingTechnologyGridSelect {...params} />,
        valueFormatter: params => params.value.toUpperCase(),
    },
    {
        headerName: 'Plant',
        field: 'recommendedPlant',
        width: 200,
        valueFormatter: params => params.value.toUpperCase(),
    },
    {
        headerName: 'Label Complexity',
        field: 'labelComplexity',
        width: 200,
        valueFormatter: params => LABEL_COMPLEXITIES[params.value as TLabelComplexityKey],
    },
    {
        headerName: 'Existing Label Similarity',
        field: 'similarityToCustomersExistingLabel',
        width: 200,
        valueFormatter: params =>
            SIMILARITIES_TO_CUSTOMERS_EXISTING_LABELS[params.value as TSimilarityToCustomersExistingLabelKey],
    },
    {
        headerName: 'End Market',
        field: 'endMarket',
        width: 200,
        valueFormatter: params => params.value?.toUpperCase(),
        editable: true,
        renderEditCell: params => <EndMarketGridSelect {...params} />,
    },
    {
        headerName: 'Baseline Margin %',
        field: 'baselineMargin',
        width: 200,
        renderCell: params => (
            <>
                {Number(params.row.baselineMargin) - Number(params.row.peerGroupPercentile) > 0.1 && (
                    <Tooltip title='This line has a high margin'>
                        <IconButton>
                            <Warning />
                        </IconButton>
                    </Tooltip>
                )}
                <span>{params.value ? (params.value === '-100' ? 'N/A' : formatPercent(params.value)) : '-'}</span>
            </>
        ),
        disableReorder: true,
    },
    {
        headerName: `${erp === ERPEnum.RADIUS && division === DivisionEnum.WANDS ? 'Markup' : 'Margin'} Floor`,
        field: 'floorMargin',
        width: 200,
        renderCell: params => (
            <span>{params.value ? (params.value === '-100' ? 'N/A' : formatPercent(params.value)) : '-'}</span>
        ),
        disableReorder: true,
        headerClassName: 'lead-group-header recommended-metrics',
        renderHeader: (params: GridColumnHeaderParams<any, ProductForm, any>) => (
            <DataGridProGroupingHeader
                backgroundColor={'#e0e8f0'}
                titleColor={'#5a5d60'}
                columnsToGroup={4}
                title={'RECOMMENDED'}
                width={params.colDef.width}
            >
                {params.colDef.headerName || ''}
            </DataGridProGroupingHeader>
        ),
    },
    {
        headerName: `Uncapped ${
            erp === ERPEnum.RADIUS && division === DivisionEnum.WANDS ? 'Markup' : 'Margin'
        } Target`,
        field: 'peerGroupPercentile',
        width: 200,
        headerClassName: 'grouped-header',
        renderCell: params => (
            <span>{params.value ? (params.value === '-100' ? 'N/A' : formatPercent(params.value)) : '-'}</span>
        ),
    },
    {
        headerName: `${erp === ERPEnum.RADIUS && division === DivisionEnum.WANDS ? 'Markup' : 'Margin'} Target`,
        field: 'targetMargin',
        width: 200,
        headerClassName: 'grouped-header',
        renderCell: params => (
            <span>{params.value ? (params.value === '-100' ? 'N/A' : formatPercent(params.value)) : '-'}</span>
        ),
    },
    {
        headerName: 'Price per Unit',
        field: 'recommendedPricePerUnit',
        width: 200,
        headerClassName: 'grouped-header',
        renderCell: params =>
            erp === ERPEnum.RADIUS && division === DivisionEnum.WANDS ? (
                <span>
                    {formatMoney(Number(params.row.estimatedCostPerUnit) * (1 + Number(params.row.targetMargin)))}
                </span>
            ) : (
                <span>
                    {formatMoney(Number(params.row.estimatedCostPerUnit) / (1 - Number(params.row.targetMargin)))}
                </span>
            ),
        valueGetter: params => Number(params.row.estimatedCostPerUnit) / (1 - Number(params.row.targetMargin)),
    },
    {
        headerName: `Final ${erp === ERPEnum.RADIUS && division === DivisionEnum.WANDS ? 'Markup' : 'Margin'}`,
        field: 'finalMargin',
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        width: 200,
        renderCell: params => <span>{params.value ? formatPercent(params.value) : '-'}</span>,
        valueGetter: params => (params.value ? params.value : ''),
        disableReorder: true,
        headerClassName: 'lead-group-header revised-metrics',
        renderHeader: (params: GridColumnHeaderParams<any, ProductForm, any>) => (
            <DataGridProGroupingHeader
                backgroundColor={'#d6f1fd'}
                titleColor={'#00446a'}
                columnsToGroup={2}
                title={'REVISED'}
                width={params.colDef.width}
            >
                {params.colDef.headerName || ''}
            </DataGridProGroupingHeader>
        ),
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            const hasError = !params.props.value || params.props.value < 0.0 || params.props.value > 1.0;
            return { ...params.props, error: hasError };
        },
        renderEditCell: params => (
            <FinalMarginInputGrid
                erp={erp}
                division={division}
                params={params}
                updateIsReviewedOnFinalMarginChange={updateIsReviewedOnFinalMarginChange}
                updateFinalMarginOnRevisedPriceChange={updateFinalMarginOnRevisedPriceChange}
            />
        ),
        editable: true,
    },
    {
        headerName: 'Price per Unit',
        field: 'revisedPricePerUnit',
        width: 200,
        headerClassName: 'grouped-header',
        renderCell: params => (
            <span>
                {params.row.revisedPricePerUnit
                    ? formatMoney(Number(params.row.revisedPricePerUnit))
                    : formatMoney(
                          Number(params.row.finalMargin) === 1
                              ? 0
                              : erp === ERPEnum.RADIUS && division === DivisionEnum.WANDS
                              ? Number(params.row.estimatedCostPerUnit) * (1 + Number(params.row.finalMargin))
                              : Number(params.row.estimatedCostPerUnit) / (1 - Number(params.row.finalMargin))
                      )}
            </span>
        ),
        valueGetter: params =>
            params.row.revisedPricePerUnit
                ? Number(params.row.revisedPricePerUnit)
                : Number(params.row.finalMargin) === 1
                ? 0
                : erp === ERPEnum.RADIUS && division === DivisionEnum.WANDS
                ? Number(params.row.estimatedCostPerUnit) * (1 + Number(params.row.finalMargin))
                : Number(params.row.estimatedCostPerUnit) / (1 - Number(params.row.finalMargin)),
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            const hasError = !params.props.value || params.props.value < 0;
            return { ...params.props, error: hasError };
        },
        renderEditCell: params => (
            <RevisedPriceInputGrid
                params={params}
                erp={erp}
                division={division}
                updateIsReviewedOnFinalMarginChange={updateIsReviewedOnFinalMarginChange}
                updateFinalMarginOnRevisedPriceChange={updateFinalMarginOnRevisedPriceChange}
            />
        ),
        editable: true,
    },
    {
        headerName: `${erp === ERPEnum.RADIUS && division === DivisionEnum.WANDS ? 'Markup' : 'Margin'} Variance`,
        field: 'varianceMargin',
        width: 200,
        renderCell: params => (
            <span>
                {formatPercent(
                    Number(Number(params.row.finalMargin).toFixed(4)) -
                        Number(Number(params.row.targetMargin).toFixed(4))
                )}
            </span>
        ),
        valueGetter: params => Number(params.row.finalMargin) - Number(params.row.targetMargin),
    },
    {
        headerName: `Quote Line #`,
        field: 'productKey',
        width: 200,
        renderCell: params => <ProductIdGridDisplay {...params} />,
    },
];
