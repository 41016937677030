import { Box, Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { DateInput, SelectInput, TextInput } from 'shared/components/forms/controls';
import {
    COMPETITIVENESS_OPTIONS,
    CONTRACT_OR_SPOT_BUY_LABELS,
    CONTRACT_OR_SPOT_BUY_OPTIONS,
    SHARE_OF_CUSTOMER_LABEL_SPEND_OPTIONS,
    SHARE_OF_CUSTOMER_LABEL_SPENDS,
} from 'shared/constants';
import { TCompetitivenessKey, TContractOrSpotBuy, TShareOfCustomersLabelSpendKey, QuoteStatusEnum } from 'shared/types';
import { SalesRepName } from './sales-rep-name';
import { DEAL_INFO_SUMMARY_STYLES } from './styles';
import { KeyCompetitorDropdown } from './key-competitor-dropdown';
import { useQuoteSizeAdder } from 'shared/queries';

export function DealInfoFields() {
    const { getValues } = useFormContext();
    const { quoteSize } = useQuoteSizeAdder();
    const isStatusInProcess = getValues('status') === QuoteStatusEnum.IN_PROCESS;

    return (
        <Box>
            <Typography variant='h6' sx={DEAL_INFO_SUMMARY_STYLES.boxStyle}>
                Deal Attributes
            </Typography>
            <Box sx={DEAL_INFO_SUMMARY_STYLES.boxStyle}>
                <Grid container spacing={1}>
                    <Grid xs={12} md={6} item>
                        <SalesRepName name={'salesRepName'} />
                    </Grid>

                    <Grid xs={12} md={6} item>
                        <DateInput
                            name={'date'}
                            label='Date'
                            rules={{
                                required: {
                                    message: 'Quote Date is required.',
                                    value: !isStatusInProcess,
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={DEAL_INFO_SUMMARY_STYLES.gridStyles}>
                    <Grid xs={12} md={6} item>
                        <SelectInput
                            name={'contractOrSpotBuy'}
                            label='Contract or Spot-Buy'
                            options={CONTRACT_OR_SPOT_BUY_OPTIONS}
                            getOptionLabel={(option: TContractOrSpotBuy) => CONTRACT_OR_SPOT_BUY_LABELS[option] || ''}
                            getOptionSelected={(option: TContractOrSpotBuy, value: TContractOrSpotBuy) =>
                                option === value
                            }
                            rules={{
                                required: {
                                    message: 'Please select an option to continue',
                                    value: !isStatusInProcess,
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <SelectInput
                            name={'shareOfCustomersLabelSpend'}
                            label="Share of Customer's Label Spend"
                            options={SHARE_OF_CUSTOMER_LABEL_SPEND_OPTIONS}
                            getOptionLabel={(option: TShareOfCustomersLabelSpendKey) =>
                                SHARE_OF_CUSTOMER_LABEL_SPENDS[option] || ''
                            }
                            getOptionSelected={(
                                option: TShareOfCustomersLabelSpendKey,
                                value: TShareOfCustomersLabelSpendKey
                            ) => option === value}
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <SelectInput
                            name={'competitiveness'}
                            label='Competitiveness'
                            options={COMPETITIVENESS_OPTIONS}
                            getOptionLabel={(option: TCompetitivenessKey) => option || ''}
                            getOptionSelected={(option: TCompetitivenessKey, value: TCompetitivenessKey) =>
                                option === value
                            }
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <KeyCompetitorDropdown name={'keyCompetitor'} />
                    </Grid>
                </Grid>
                <Grid sx={DEAL_INFO_SUMMARY_STYLES.gridStyles} xs={12} item>
                    <SelectInput
                        name={'quoteSizeAdder'}
                        label='Quote Size Adder'
                        options={quoteSize}
                        getOptionLabel={displayname => displayname || ''}
                        getOptionSelected={(option: string, value: string) => option === value}
                    />
                </Grid>
                <Grid sx={DEAL_INFO_SUMMARY_STYLES.gridStyles} xs={12} item>
                    <TextInput name={'quoteDescription'} label='Quote Description' multiline rows={3.8} />
                </Grid>
            </Box>
        </Box>
    );
}
