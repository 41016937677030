import { useCallback, useRef, useState } from 'react';
import { formatBytes } from 'shared/utility';

export function useFile(accept: string) {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [file, setFile] = useState<File | null>(null);

    const clearFile = () => {
        setFile(null);
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    const onFileChange = useCallback(() => {
        if (inputRef.current?.value && inputRef.current.files?.[0]) {
            const userFile: File = inputRef.current.files?.[0] as File;
            setFile(userFile);
        } else clearFile();
    }, []);

    const triggerFileSelect = useCallback(() => {
        inputRef.current?.click();
    }, []);

    const FileInput: () => JSX.Element = useCallback(
        () => <input ref={inputRef} onChange={onFileChange} accept={accept} hidden type='file' />,
        [accept, inputRef, onFileChange]
    );

    return {
        FileInput,
        file,
        clearFile,
        triggerFileSelect,
        displaySize: formatBytes(file?.size, 2),
        displayModifiedBy: new Date(file?.lastModified || 0).toLocaleString(),
    };
}
