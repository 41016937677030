import { useState, useCallback } from 'react';

export function useCopyToClipboard(text: string) {
    const [isCopied, setIsCopied] = useState(false);

    const copy = useCallback(async () => {
        await navigator.clipboard.writeText(text);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1500);
    }, [text]);

    return { copy, isCopied } as const;
}
