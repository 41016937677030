import { useService } from '@insight2profit/drive-app';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { QUERY_KEYS } from 'shared/constants';
import { IBrand } from 'shared/types';

export function useBrandSearch(
    query: string,
    businessUnit: string,
    user: app.UserInfo | null,
    isRadius: boolean,
    isFandB: boolean,
    isWandS: boolean
) {
    const { productService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const initialData: IBrand[] = [];
    const { data, isFetching, isLoading } = useQuery<IBrand[], Error>({
        queryKey: [QUERY_KEYS.brands, query, businessUnit],
        queryFn: () => productService.getBrands(businessUnit, user, isRadius, isFandB, isWandS),
        initialData,
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            return initialData;
        },
    });

    return { data, isFetching, isLoading } as const;
}
