import { Close, Send } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectInput } from 'shared/components/forms/controls';
import { useGridApiRows } from 'shared/hooks';
import { MassActionForm, MassActionSelection } from 'shared/types';
import { PRODUCT_MASS_ACTION_MODAL_STYLES } from '../styles';
import { DynamicMarginFields } from './dynamic-margin-fields';

interface MassActionsModalProps {
    open: boolean;
    onClose: () => void;
    confirm: (form: MassActionForm) => void;
}

const MASS_ACTION_TYPES: MassActionSelection[] = [
    { type: 'floor', display: 'Floor Margin' },
    { type: 'target', display: 'Target Margin' },
    { type: 'override', display: 'Override' },
];

const DEFAULT_MASS_ACTION_FORM: MassActionForm = {
    type: null,
    change: '',
};

export function MassActionsModal({ open, onClose, confirm }: MassActionsModalProps) {
    const methods = useForm<MassActionForm>({ defaultValues: DEFAULT_MASS_ACTION_FORM });
    const { selectedRowsCount, visibleRowsCount, clearSelectedRows } = useGridApiRows();

    const submit = async (form: MassActionForm) => {
        await confirm(form);
        clearSelectedRows();
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Final Margin Mass Actions</DialogTitle>
            <DialogTitle sx={{ color: 'primary.main' }}>WARNING</DialogTitle>
            <DialogContent>
                {`You are about to change the final margin for 
                ${selectedRowsCount || visibleRowsCount} product${
                    (selectedRowsCount || visibleRowsCount) > 1 ? 's' : ''
                }.`}
                <FormProvider {...methods}>
                    <Box
                        component='form'
                        id='mass-actions-form'
                        sx={PRODUCT_MASS_ACTION_MODAL_STYLES.formContainerStyle}
                        onSubmit={methods.handleSubmit(submit)}
                    >
                        <Box>
                            <SelectInput
                                name='type'
                                label='Select Type'
                                options={MASS_ACTION_TYPES}
                                getOptionLabel={option => option.display}
                                getOptionSelected={(option, value) => option.type === value.type}
                                rules={{
                                    required: {
                                        message: 'Please select a a mass action type to continue',
                                        value: true,
                                    },
                                }}
                            />
                        </Box>
                        <Box>
                            <DynamicMarginFields />
                        </Box>
                        <Box sx={{ textAlign: 'right' }}>
                            <Button startIcon={<Close />} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                startIcon={<Send />}
                                type='submit'
                                form='mass-actions-form'
                                onClick={methods.handleSubmit(submit)}
                            >
                                Confirm
                            </Button>
                        </Box>
                    </Box>
                </FormProvider>
            </DialogContent>
        </Dialog>
    );
}
