import { WarningAmber } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { PERMISSIONS } from 'shared/constants';

interface IWAndSWarningModalProps {
    showModal: boolean;
    divisionType: string[];
    handleClose: () => void;
}

export function WAndSWarningModal({ showModal, divisionType, handleClose }: IWAndSWarningModalProps) {
    const divisionTypeFromText = divisionType[0] === PERMISSIONS.wands ? 'Markup' : 'Margin';
    const divisionTypeToText = divisionType[0] === PERMISSIONS.wands ? 'Margin' : 'Markup';

    return (
        <Dialog
            fullWidth
            open={showModal}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title' textAlign='center'>
                <WarningAmber fontSize='large' color='secondary' />
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant='h6'>
                        Changing your division {divisionType[0] === PERMISSIONS.wands ? 'to' : 'from'} W&S will allow
                        you to see "{divisionTypeFromText}" and will remove the ability to see "{divisionTypeToText}".
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button variant='contained' color='primary' onClick={handleClose}>
                    okay
                </Button>
            </DialogActions>
        </Dialog>
    );
}
