import { AppForm } from 'shared/components/forms';
import { ProfileConfigurationPage } from './page';
import { usePermissionERPDivisionState } from 'shared/contexts';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routing';
import { WAndSWarningModal } from './wands-warning-modal';
import { PERMISSIONS } from 'shared/constants';
import { IProfileConfiguration } from 'shared/types';

export function ProfileConfigurationResolver() {
    const { divisionEnabled, setDivisionEnabled, erpEnabled, setERPEnabled } = usePermissionERPDivisionState();
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [dataToSave, setDataToSave] = useState<IProfileConfiguration>({ divisionEnabled, erpEnabled });
    const navigate = useNavigate();

    const executeSave = useCallback(
        (data: IProfileConfiguration) => {
            setDivisionEnabled(data.divisionEnabled);
            setERPEnabled(data.erpEnabled);
            navigate(ROUTES.quote);
        },
        [setDivisionEnabled, setERPEnabled, navigate]
    );

    const submit = useCallback(
        (data: IProfileConfiguration) => {
            setDataToSave(data);
            if (
                data.erpEnabled === PERMISSIONS.radius &&
                (data.divisionEnabled.some(d => d === PERMISSIONS.wands) ||
                    divisionEnabled.some(d => d === PERMISSIONS.wands))
            ) {
                setShowWarningModal(true);
            } else {
                executeSave(data);
            }
        },
        [divisionEnabled, setDataToSave, setShowWarningModal, executeSave]
    );

    const handleCloseWarningModal = useCallback(() => {
        setShowWarningModal(false);
        executeSave(dataToSave);
    }, [dataToSave, executeSave, setShowWarningModal]);

    return (
        <AppForm id='profile-configuration-form' defaultForm={dataToSave} onSubmit={submit}>
            <ProfileConfigurationPage />
            <WAndSWarningModal
                showModal={showWarningModal}
                divisionType={dataToSave.divisionEnabled}
                handleClose={handleCloseWarningModal}
            />
        </AppForm>
    );
}
