import { useUser } from '@insight2profit/drive-app';
import { SelectInput } from 'shared/components/forms/controls';
import { usePermissionERPDivisionState } from 'shared/contexts';
import { useSalesRepName } from 'shared/queries';
import { DivisionEnum, ERPEnum } from 'shared/types';
import { getDivisionByPermission, getERPByPermission } from 'shared/utility';

interface ISalesRepNameProps {
    name?: string;
}

export function SalesRepName({ name = 'salesrepname' }: ISalesRepNameProps) {
    const user = useUser();
    const { divisionEnabled, erpEnabled } = usePermissionERPDivisionState();

    const isRadius = getERPByPermission(erpEnabled) === ERPEnum.RADIUS;
    const isWandS = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.WANDS);
    const isFandB = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.FANDB);

    const businessUnit = '';
    const { data, isLoading, isFetching } = useSalesRepName(businessUnit, user, isRadius, isFandB, isWandS);

    return (
        <SelectInput
            name={name}
            label='Sales Rep Name'
            loading={isLoading || isFetching}
            options={data?.sort((a, b) => a.localeCompare(b)) || []}
            getOptionLabel={(option: string) => (option || '').toUpperCase()}
            getOptionSelected={(option: string, salesrepname: string) => option === salesrepname}
        />
    );
}
