import {
    TBusinessTypeKey,
    TCompetitivenessKey,
    TContractOrSpotBuy,
    TSimilarityToCustomersExistingLabelKey,
    TLabelComplexityKey,
    TShareOfCustomersLabelSpendKey,
    TSimilarityToCustomersExistingLabelValue,
    TLabelComplexityValue,
    TShareOfCustomersLabelSpendValue,
    TTier,
} from 'shared/types';

export const CONTRACT_OR_SPOT_BUY_OPTIONS: TContractOrSpotBuy[] = ['CONTRACT', 'SPOT-BUY', 'OFF-CONTRACT-SPOT-BUY'];

export const CONTRACT_OR_SPOT_BUY_LABELS: Record<TContractOrSpotBuy, string> = {
    CONTRACT: 'CONTRACT/ONGOING BUSINESS',
    'SPOT-BUY': 'SPOT-BUY',
    'OFF-CONTRACT-SPOT-BUY': 'OFF-CONTRACT SPOT-BUY',
};

export const SIMILARITIES_TO_CUSTOMERS_EXISTING_LABELS_OPTIONS: TSimilarityToCustomersExistingLabelKey[] = [
    'LOW-TO-MODERATE',
    'HIGH',
];

export const SIMILARITIES_TO_CUSTOMERS_EXISTING_LABELS: Record<
    TSimilarityToCustomersExistingLabelKey,
    TSimilarityToCustomersExistingLabelValue
> = {
    'LOW-TO-MODERATE': 'LOW TO MODERATE',
    HIGH: 'HIGH',
};

export const SIMILARITIES_TO_CUSTOMERS_EXISTING_TOOLTIPS: Record<TSimilarityToCustomersExistingLabelKey, string> = {
    'LOW-TO-MODERATE': 'NEW PEER GROUP OR SIGNIFICANTLY DIFFERENT LABEL SIZE',
    HIGH: 'SMALL CHANGES FROM AN EXISTING PRODUCT',
};

export const LABEL_COMPLEXITY_OPTIONS: TLabelComplexityKey[] = ['TCS-MACHINE', 'HIGH', 'MEDIUM', 'LOW', 'BLANK LABEL'];

export const LABEL_COMPLEXITIES: Record<TLabelComplexityKey, TLabelComplexityValue> = {
    'TCS-MACHINE': 'TCS MACHINE',
    HIGH: 'HIGH',
    MEDIUM: 'MEDIUM',
    LOW: 'LOW',
    'BLANK LABEL': 'BLANK LABEL',
};
export const LABEL_COMPLEXITIES_TOOLTIPS: Record<TLabelComplexityKey, string> = {
    'TCS-MACHINE': 'W&S NEW BUSINESS ONLY',
    HIGH: 'EX. >10 STATIONS/COMPLEX EMBELLISHMENTS',
    MEDIUM: '',
    LOW: '',
    'BLANK LABEL': '',
};

export const SHARE_OF_CUSTOMER_LABEL_SPEND_OPTIONS: TShareOfCustomersLabelSpendKey[] = [
    '100%-FOR-SEVERAL-YEARS',
    '100%-OF-BUSINESS-SPEND',
    'MAJORITY-OF-BUSINESS',
    'MINORITY-OF-BUSINESS',
    'NONE',
];

export const SHARE_OF_CUSTOMER_LABEL_SPENDS: Record<
    TShareOfCustomersLabelSpendKey,
    TShareOfCustomersLabelSpendValue
> = {
    '100%-FOR-SEVERAL-YEARS': '100% FOR SEVERAL YEARS',
    '100%-OF-BUSINESS-SPEND': '100% OF BUSINESS SPEND',
    'MAJORITY-OF-BUSINESS': 'MAJORITY OF BUSINESS',
    'MINORITY-OF-BUSINESS': 'MINORITY OF BUSINESS',
    NONE: 'NONE',
};

export const COMPETITIVENESS_OPTIONS: TCompetitivenessKey[] = ['LOW', 'MEDIUM', 'HIGH'];

export const BUSINESS_TYPE_OPTIONS: TBusinessTypeKey[] = ['NEW', 'EXISTING'];

export const TIER_TOOLTIPS: Record<TTier, string> = {
    'tier 1': '$10M+',
    'tier 2': '$5M - $10M',
    'tier 3': '$1M - $5M',
    'tier 4': '$100k - $1M',
    'tier 5': '$100k and below',
};

export const EXPORT_QUOTE_ID = 'export-quote' as const;
