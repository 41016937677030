import { GridValueGetterParams } from '@mui/x-data-grid-premium';
import { format } from 'date-fns/fp';

const moneyFormatter = (decimalPlaces: number) => {
    return new Intl.NumberFormat('en', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    });
};
const percentFormatter = new Intl.NumberFormat('en', {
    style: 'percent',
    minimumFractionDigits: 2,
    minimumIntegerDigits: 2,
});
export const formatMoney = (value: number) => moneyFormatter(4).format(value);
export const formatMoneyNoDecimals = (value: number) => moneyFormatter(0).format(value);
export const formatPercent = (value: number) => percentFormatter.format(value);

export const formatDate = format('MM-dd-yy');
export const formatSlashDate = format('MM/dd/yyyy');

export const formatDateWithTime = format('MMddyyhhmm');

export const formatToDecimals = (value: number, decimals: number = 2): string =>
    isNaN(value) ? '0' : value.toFixed(decimals);

export const formatToTitleCase = (words: string) =>
    words
        ? words
              .trim()
              .split(' ')
              .map(([firstLetter, ...rest]) =>
                  firstLetter
                      ? `${firstLetter.toUpperCase()}${rest && rest.length ? rest.join('').toLowerCase() : ''}`
                      : ''
              )
              .join(' ')
        : '';

export function formatObjectValuesToUppercase<T>(obj: T, keysToExclude?: string[]): T {
    if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
            formatObjectValuesToUppercase(obj[i], keysToExclude);
        }
    } else if (typeof obj === 'object') {
        for (const key in obj) {
            if (typeof obj[key] === 'string' && !keysToExclude?.includes(key)) {
                ((obj[key] as unknown) as string) = ((obj[key] as unknown) as string).toUpperCase();
                continue;
            }
            formatObjectValuesToUppercase(obj[key], keysToExclude);
        }
    }
    return obj;
}

export function columnFormatterSlashDate(params: GridValueGetterParams) {
    if (!params.value) return '';
    return formatSlashDate(new Date(params.value) as Date);
}

export function formatBytes(bytes: number | undefined, decimals = 2) {
    if (!bytes) return '0';
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function getFactors(nr: number): number[][] {
    var parts = [];
    while (nr > 1) {
        for (var i = 2; i <= nr; i++) {
            if (nr % i) continue;
            parts.push([nr, i]);
            nr = nr / i;
            break;
        }
    }
    parts.push([1, 1]);
    return parts;
}
