import { useQuery } from '@tanstack/react-query';
import { IProductMarginHistogram } from 'shared/types';
import { useSnackbar } from 'notistack';
import { QUERY_KEYS } from 'shared/constants';
import { useService } from '@insight2profit/drive-app';

export function useProductHistogramData(peerGroup: string) {
    const { productService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const initialData: IProductMarginHistogram[] = [];
    const { data, isFetching, isLoading } = useQuery<IProductMarginHistogram[], Error>({
        queryKey: [QUERY_KEYS.productHistogram, peerGroup],
        queryFn: () => productService.getProductHistogramData(peerGroup),
        initialData,
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            return initialData;
        },
    });

    if (!data) {
        return { data: initialData, isFetching, isLoading } as const;
    }

    return { data, isFetching, isLoading } as const;
}
