import { Box, Grid } from '@mui/material';
import { TextInput } from 'shared/components/forms/controls';
import { NOTES_FIELDS_STYLES } from './styles';
import { useUser } from '@insight2profit/drive-app';

export function ReviewNotesFields() {
    const user = useUser();

    return (
        <Box>
            <Grid sx={NOTES_FIELDS_STYLES.gridStyles} xs={12} item>
                <TextInput
                    name={'reviewedByNotes'}
                    label='Reviewed By Notes'
                    multiline={true}
                    rows={4}
                    disabled={!user?.isPricingLeadership}
                />
            </Grid>
        </Box>
    );
}
