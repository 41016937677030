import { Help } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { NumberInput, SelectInput } from 'shared/components/forms/controls';
import { BUSINESS_TYPE_OPTIONS, TIER_TOOLTIPS } from 'shared/constants';
import { useSelectFormCustomer } from 'shared/hooks';
import { useCustomerTierSearch } from 'shared/queries';
import { QuoteStatusEnum, TBusinessTypeKey, TTier } from 'shared/types';
import { CustomerDropdown } from './customer-dropdown';
import { CUSTOMER_FIELDS_STYLES } from './styles';
import { CustomerOverrideDropdown } from './customer-override-dropdown';
import { useSelectFormCustomerOverride } from 'shared/hooks/use-select-form-customer-override';

export function CustomerFields() {
    const [onCustomerSelect, onCustomerClear] = useSelectFormCustomer();
    const [onCustomerOverrideSelect, onCustomerOverrideClear] = useSelectFormCustomerOverride();
    const { data: customerTierData, isLoading: isTierLoading, isFetching: isTierFetching } = useCustomerTierSearch();

    const { getValues } = useFormContext();
    const isStatusInProcess = getValues('status') === QuoteStatusEnum.IN_PROCESS;

    return (
        <Box>
            <Typography variant='h6' sx={{ paddingTop: '10px', margin: 'auto' }}>
                Customer Lookup
            </Typography>
            <Box sx={CUSTOMER_FIELDS_STYLES.boxStyle}>
                <Grid container spacing={1}>
                    <Grid xs={12} item>
                        <CustomerDropdown name={'customerName'} onClear={onCustomerClear} onSelect={onCustomerSelect} />
                    </Grid>
                    <Grid xs={12} item>
                        <SelectInput
                            name={'tier'}
                            label='Tier'
                            options={customerTierData?.sort((a, b) => a.localeCompare(b)) || []}
                            loading={isTierLoading || isTierFetching}
                            getOptionLabel={option => (option || '').toUpperCase()}
                            getOptionSelected={(option, value) => option === value}
                            optionIcon={<Help />} // defining key and label again to add the tooltip
                            optionKey={(option: TTier) => option.toUpperCase()}
                            optionLabel={(option: TTier) => option.toUpperCase()}
                            optionTooltip={(option: TTier) => TIER_TOOLTIPS[option]}
                            rules={{
                                required: {
                                    message: 'Please select a Tier to continue',
                                    value: !isStatusInProcess,
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <NumberInput
                            name={'achievableSpend'}
                            label='Achievable Spend'
                            prefix='$'
                            rules={{
                                min: {
                                    message: 'Achievable Spend must be greater than 0',
                                    value: 0.001,
                                },
                            }}
                            decimalPlaces={4}
                        />
                    </Grid>

                    <Grid xs={12} md={12} item>
                        <SelectInput
                            name={'newOrExisting'}
                            label='New or Existing Business'
                            options={BUSINESS_TYPE_OPTIONS}
                            getOptionLabel={(option: TBusinessTypeKey) => option || ''}
                            getOptionSelected={(option: TBusinessTypeKey, value: TBusinessTypeKey) => option === value}
                            rules={{
                                required: {
                                    message: 'Please select if it is a new or an existing Business to continue',
                                    value: !isStatusInProcess,
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <CustomerOverrideDropdown
                            name={'customerNameOverride'}
                            onClear={onCustomerOverrideClear}
                            onSelect={onCustomerOverrideSelect}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
