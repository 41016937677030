import { Box, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ProductForm } from 'shared/types';
import {
    calculateDeltaAnnualRevenue,
    calculateRecommendedAnnualRevenue,
    calculateRevisedAnnualRevenue,
    calculateWeightedAverageMarginVariance,
    formatMoneyNoDecimals,
    formatPercent,
    getDivisionByPermission,
    getERPByPermission,
} from 'shared/utility';
import { DEAL_LEVEL_SUMMARY_STYLES } from './styles';
import { usePermissionERPDivisionState } from 'shared/contexts';

export function DealLevelSummary() {
    const { watch } = useFormContext();
    const { divisionEnabled, erpEnabled } = usePermissionERPDivisionState();

    const erp = getERPByPermission(erpEnabled);
    const division = getDivisionByPermission(divisionEnabled[0]);
    const watchProducts = watch('products') as ProductForm[];
    const recommendedSellAnnualRevenue = calculateRecommendedAnnualRevenue(watchProducts, erp, division);
    const revisedAnnualRevenue = calculateRevisedAnnualRevenue(watchProducts, erp, division);
    const deltaAnnualRevenue = calculateDeltaAnnualRevenue(recommendedSellAnnualRevenue, revisedAnnualRevenue);
    const weightedAverageMarginVariance = calculateWeightedAverageMarginVariance(
        deltaAnnualRevenue,
        recommendedSellAnnualRevenue
    );

    return (
        <Box sx={DEAL_LEVEL_SUMMARY_STYLES.boxStyle}>
            <Grid container spacing={0}>
                <Grid xs={12} item>
                    <Grid container spacing={0} sx={DEAL_LEVEL_SUMMARY_STYLES.contentStyle}>
                        <Grid xs={12} sx={DEAL_LEVEL_SUMMARY_STYLES.headerStyle} item>
                            Annual Revenue
                        </Grid>
                        <Grid xs={3} item sx={DEAL_LEVEL_SUMMARY_STYLES.gridStyle}>
                            <Grid sx={DEAL_LEVEL_SUMMARY_STYLES.textBold}>Recommended</Grid>
                            <Grid>{formatMoneyNoDecimals(recommendedSellAnnualRevenue)}</Grid>
                        </Grid>
                        <Grid xs={3} item sx={DEAL_LEVEL_SUMMARY_STYLES.gridStyle}>
                            <Grid sx={DEAL_LEVEL_SUMMARY_STYLES.textBold}>Revised</Grid>
                            <Grid>{formatMoneyNoDecimals(revisedAnnualRevenue)}</Grid>
                        </Grid>
                        <Grid xs={3} item sx={DEAL_LEVEL_SUMMARY_STYLES.gridStyle}>
                            <Grid sx={DEAL_LEVEL_SUMMARY_STYLES.textBold}>Revised vs. Recommended Variance</Grid>
                            <Grid>{formatMoneyNoDecimals(deltaAnnualRevenue)}</Grid>
                        </Grid>
                        <Grid
                            xs={3}
                            item
                            sx={{
                                ...DEAL_LEVEL_SUMMARY_STYLES.gridStyle,
                                ...DEAL_LEVEL_SUMMARY_STYLES.borderLeftLightGray,
                            }}
                        >
                            <Grid sx={DEAL_LEVEL_SUMMARY_STYLES.textBold}>Revenue % Variance</Grid>
                            <Grid>{formatPercent(weightedAverageMarginVariance)}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
