import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routing/routes';
import { QUERY_KEYS } from 'shared/constants';
import { IQuote } from 'shared/types';
import { useService } from '@insight2profit/drive-app';

export function useQuote(id: string) {
    const { quoteService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { data, isLoading, isFetching } = useQuery<IQuote, Error>({
        queryKey: [QUERY_KEYS.singleQuote, id],
        queryFn: () => quoteService.getQuote(id),
        onError: () => {
            enqueueSnackbar(`Quote ${id} does not exist..`, { variant: 'error' });
            navigate(ROUTES.quote);
        },
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        data,
        isLoading,
        isFetching,
    } as const;
}
