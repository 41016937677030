import { Email, IEventService } from '@price-for-profit/micro-services';

export interface INotificationService {
    sendEmail(email: Email): Promise<void>;
}

export class NotificationService implements INotificationService {
    constructor(private eventService: IEventService) {}
    async sendEmail(email: Email): Promise<void> {
        await this.eventService.sendSimpleEmail(email);
    }
}
