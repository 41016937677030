import { Skeleton } from '@mui/material';
import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useProductScatterData } from 'shared/queries';
import { ProductForm, TProductFormWithId } from 'shared/types';
import { formatMoneyNoDecimals, formatPercent, getDivisionByPermission, getERPByPermission } from 'shared/utility';
import { graphBuilder } from './graphBuilder';
import { usePermissionERPDivisionState } from 'shared/contexts';

interface ProductScatterProps {
    product: TProductFormWithId;
    quoteProducts: ProductForm[];
}

export function ProductScatterChart({ product, quoteProducts }: ProductScatterProps) {
    const { data, isLoading, isFetching } = useProductScatterData(product.peerGroup);
    const { divisionEnabled, erpEnabled } = usePermissionERPDivisionState();
    const division = getDivisionByPermission(divisionEnabled[0]);
    const erp = getERPByPermission(erpEnabled);
    const { series, averageMargin } = useMemo(() => graphBuilder(data || [], quoteProducts, product, erp, division), [
        data,
        quoteProducts,
        product,
        erp,
        division,
    ]);
    const options: ApexCharts.ApexOptions = {
        annotations: {
            yaxis: [
                {
                    y: averageMargin,
                    borderColor: '#212121',
                    borderWidth: 3,
                    opacity: 0,
                    strokeDashArray: 0,
                    label: {
                        borderColor: '#212121',
                        borderWidth: 2,
                        style: {
                            color: '#000000',
                            background: '#fdd835',
                            fontFamily: 'roboto',
                            fontWeight: 'bold',
                        },
                        text: `${formatPercent(averageMargin)} Average Margin Line`,
                    },
                },
            ],
        },
        tooltip: {
            z: {
                title: 'Product:',
            },
            y: {
                formatter: function (value: any) {
                    return `Margin:  ${formatPercent(value)}`;
                },
            },
            x: {
                formatter: function (value: any) {
                    return `Revenue: ${formatMoneyNoDecimals(value)}`;
                },
            },
        },
        title: {
            text: '',
            align: 'center',
            style: {
                fontFamily: 'Roboto',
                fontSize: '8px',
            },
        },
        chart: {
            height: 350,
            stacked: false,
            zoom: {
                enabled: true,
            },
            events: {
                beforeZoom: (e, { xaxis }) => {
                    if (xaxis.min < 0) {
                        return {
                            // dont zoom out any further
                            xaxis: {
                                min: 0,
                            },
                        };
                    }
                },
            },
        },
        xaxis: {
            type: 'numeric',
            min: 0,
            labels: {
                formatter: function (value: any) {
                    return formatMoneyNoDecimals(value);
                },
            },
            title: {
                text: 'Revenue',
                style: {
                    fontFamily: 'roboto',
                    fontSize: '16px',
                },
            },
        },
        yaxis: {
            title: {
                text: 'Margin %',
                style: {
                    fontFamily: 'roboto',
                    fontSize: '16px',
                },
            },
            min: 0,
            labels: {
                formatter: function (value: any) {
                    return formatPercent(value);
                },
            },
        },
        noData: {
            text: 'No historical data available',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
        },
    };
    return (
        <>
            {isLoading || isFetching ? (
                <Skeleton variant='rectangular' height={365} />
            ) : (
                <ReactApexChart options={options} series={series} type='scatter' height={350} />
            )}
        </>
    );
}
