import { getAppPermissions, useUser } from '@insight2profit/drive-app';
import { AccountCircle, NavigateBefore, Save } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routing';
import { DivisionGroupSwitch } from './division-group-switch';
import { ERPGroupSwitch } from './erp-group-switch';
import { useFormContext } from 'react-hook-form';
import { IProfileConfiguration } from 'shared/types';
import { DIVISION_OPTIONS, ERP_OPTIONS } from 'shared/constants';

export function ProfileConfigurationPage() {
    const user = useUser();
    const permissions = getAppPermissions(user.insightClaims);
    const { watch } = useFormContext<IProfileConfiguration>();

    const navigate = useNavigate();
    return (
        <>
            <Box height='calc(100vh - 115px)' sx={{ p: 3 }}>
                <Box mb={3} display={'flex'} justifyContent={'space-between'}>
                    <Button startIcon={<NavigateBefore />} onClick={() => navigate(ROUTES.quote)} variant='outlined'>
                        Go back
                    </Button>
                    {(ERP_OPTIONS.filter(d => permissions.includes(d.key as `mcc.${string}`)).length > 1 ||
                        DIVISION_OPTIONS.filter(d => permissions.includes(d.key as `mcc.${string}`)).length > 1) && (
                        <Button
                            startIcon={<Save />}
                            type='submit'
                            variant='contained'
                            disabled={watch('divisionEnabled').length === 0}
                        >
                            Save Configuration
                        </Button>
                    )}
                </Box>
                <Box display={'flex'}>
                    <Box mr={2}>
                        <AccountCircle color='primary' sx={{ fontSize: '3em' }} />
                    </Box>
                    <Box display={'flex'} flexDirection={'column'}>
                        <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                            {(user?.displayName || '').toUpperCase()}
                        </Typography>
                        <Typography variant='subtitle2'>{user?.email}</Typography>
                    </Box>
                </Box>
                <Divider />
                <ERPGroupSwitch />
                <DivisionGroupSwitch />
            </Box>
        </>
    );
}
