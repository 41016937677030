export const DATASTORE_LOOKUP = 'lookup';
export const LOOKUP_TABLES = {
    plantLookup: 'plantlookup',
    printTechLookup: 'printtechlookup',
    substrateLookup: 'substratelookup',
    brandLookup: 'brandlookup',
    recommendPlantLookup: 'plantlookup',
    salesRepNameLookup: 'salesrepnamelookup',
    businessUnitLookup: 'businessunitlookup',
    endMarketLookup: 'endmarketlookup',
    customerTierLookup: 'customertierlookup',
    customerLookup: 'customerlookup',
    keyCompetitorLookup: 'competitorlookup',
    productLookup: 'productlookup',
    decTechLookup: 'dectechlookup',
    dmtScatterData: 'dmtscatterdata',
    histogramDataSource: 'histogramdatasource',
    quoteSizeAdder: 'quotesizeadderslookup',
};
