import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { ISelectOption } from 'shared/types';

interface ISwitchGroupProps {
    options: ISelectOption[];
    checkedValue: string | string[];
    multiple?: boolean;
    handleChange: (key: string) => void;
}

export function SwitchGroup({ options, checkedValue, multiple, handleChange }: ISwitchGroupProps) {
    return (
        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
            {options.map(d => (
                <FormControlLabel
                    key={d.key}
                    control={
                        <Switch
                            checked={
                                multiple ? (checkedValue as string[]).some(c => c === d.key) : checkedValue === d.key
                            }
                            onChange={() => handleChange(d.key)}
                        />
                    }
                    label={d.value}
                />
            ))}
        </FormGroup>
    );
}
