import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'shared/constants';
import { QuoteSizeAddder } from 'shared/types';
import { useService } from '@insight2profit/drive-app';

export function useQuoteSizeAdder() {
    const { quoteService } = useService();
    const { enqueueSnackbar } = useSnackbar();

    const { data, isLoading, isFetching } = useQuery<QuoteSizeAddder[], Error>({
        queryKey: [QUERY_KEYS.quoteSizeAdder],
        queryFn: () => quoteService.getQuoteSizeAdder(),
        onError: () => {
            enqueueSnackbar(`Quote Size options not found.`, { variant: 'error' });
        },
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        quoteSize: data?.sort((a, b) => b.row_order - a.row_order).map(({ displayname }) => displayname) || [],
        isLoading,
        isFetching,
    } as const;
}
