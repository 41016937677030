import { IProductMarginScatter, ProductForm, TProductFormWithId } from 'shared/types';
import { calculateRevisedAnnualRevenue } from 'shared/utility';

export function graphBuilder(
    data: IProductMarginScatter[],
    quoteProducts: ProductForm[],
    product: TProductFormWithId,
    erp: string,
    division: string
) {
    const otherProducts = quoteProducts.filter(p => p.uniqueId !== product.uniqueId);

    if (data.length > 0 || otherProducts.length > 0) {
        const dataPlaceholder = data
            .filter(d => Math.round(d.ttminvoicevalue) > 0)
            .map(record => {
                return {
                    x: Math.round(record.ttminvoicevalue),
                    y: record.wtdmodelpct,
                    z: `${record.dmtscatterdatakey}`,
                };
            });
        const quoteProductsPlaceholder = otherProducts.map(p => {
            const currentOtherProductRevenue = calculateRevisedAnnualRevenue([p], erp, division);
            return {
                x: Math.round(currentOtherProductRevenue),
                y: p.finalMargin,
                z: `${p.productId} - ${(p.name || '').toUpperCase()}`,
            };
        });

        const currentProductRevenue = calculateRevisedAnnualRevenue([product], erp, division);
        const series = [
            {
                name: 'Margin',
                type: 'scatter',
                data: dataPlaceholder,
            },
            {
                name: 'Current product',
                type: 'scatter',
                data: [
                    {
                        x: Math.round(currentProductRevenue),
                        y: product.finalMargin,
                        z: `${product.productId} - ${(product.name || '').toUpperCase()}`,
                    },
                ],
            },
            {
                name: 'Other products on this quote',
                type: 'scatter',
                data: quoteProductsPlaceholder,
            },
        ];

        let averageMargin = 0;
        if (data.length > 0) {
            if (quoteProducts.length > 0) {
                averageMargin =
                    (data.map(d => d.wtdmodelpct).reduce((prev, curr) => prev + curr) +
                        Number(quoteProducts.map(d => Number(d.finalMargin)).reduce((prev, curr) => prev + curr))) /
                    (data.length + quoteProducts.length);
            } else {
                averageMargin = data.map(d => d.wtdmodelpct).reduce((prev, curr) => prev + curr) / data.length;
            }
        } else {
            averageMargin =
                Number(quoteProducts.map(d => Number(d.finalMargin)).reduce((prev, curr) => prev + curr)) /
                quoteProducts.length;
        }

        return { series, averageMargin };
    }
    return { series: [], averageMargin: 0 };
}
