import { GridRowId } from '@mui/x-data-grid-premium';
import { CalculationPayload } from '@price-for-profit/micro-services';
import {
    COMPETITIVENESS_MODEL_MAP,
    EXISTING_LABEL_SIMILARITY_MODEL_MAP,
    LABEL_COMPLEXITY_MODEL_MAP,
    LABEL_SPEND_SHARE_MODEL_MAP,
} from 'shared/constants';
import {
    DivisionEnum,
    ERPEnum,
    IQuote,
    IQuoteExcelExport,
    IRecommendedMarginsLineParamsPayload,
    IRecommendedMarginsPayload,
    ProductForm,
    ReviewedEnum,
    TDivisionKeys,
} from 'shared/types';
import { formatDate, formatToDecimals } from './formats';

export const defaultProduct: () => ProductForm = () => ({
    uniqueId: '',
    productId: '',
    isNew: false,
    name: '',
    number: '',
    brand: '',
    estimatedAnnualQuantity: '',
    estimatedCostPerUnit: '',
    similarityToCustomersExistingLabel: '',
    endMarket: '',
    baselineMargin: '',
    labelComplexity: '',
    recommendedPlant: '',
    recommendedPlantCode: '',
    decoratingTechnology: '',
    substrate: '',
    printingTechnology: '',
    floorMargin: '',
    peerGroup: '',
    peerGroupPercentile: '',
    targetMargin: '',
    finalMargin: '',
    revisedPricePerUnit: '',
    businessUnit: '',
    baselineKey: '',
    isReviewed: ReviewedEnum.NO,
});

export function buildFieldNameWithIndex<T>(index: number) {
    return (field: keyof T) => {
        const productId = `products.${index}`;
        return `${productId}.${field.toString()}` as const;
    };
}

export function calculateRecommendedAnnualRevenue(products: ProductForm[], erp: string, division: string): number {
    return products.reduce((prev: number, current: ProductForm) => {
        let recommendedPricePerUnit = Number(current.estimatedCostPerUnit) / (1 - Number(current.targetMargin)) || 0;
        if (erp === ERPEnum.RADIUS && division === DivisionEnum.WANDS) {
            recommendedPricePerUnit = Number(current.estimatedCostPerUnit) * (1 + Number(current.targetMargin)) || 0;
        }
        const currentLineRecommendedRevenue = recommendedPricePerUnit * Number(current.estimatedAnnualQuantity || 0);
        return prev + currentLineRecommendedRevenue;
    }, 0);
}

export function calculateRevisedAnnualRevenue(products: ProductForm[], erp: string, division: string): number {
    return products.reduce((prev: number, current: ProductForm) => {
        let revisedPricePerUnit = Number(current.estimatedCostPerUnit || 0) / (1 - Number(current.finalMargin)) || 0;
        if (erp === ERPEnum.RADIUS && division === DivisionEnum.WANDS) {
            revisedPricePerUnit = Number(current.estimatedCostPerUnit) * (1 + Number(current.finalMargin)) || 0;
        }
        const currentLineRevisedRevenue =
            Number(current.finalMargin) === 1 ? 0 : revisedPricePerUnit * Number(current.estimatedAnnualQuantity || 0);
        return prev + currentLineRevisedRevenue;
    }, 0);
}

export function calculateDeltaAnnualRevenue(recAnnualRev: number, revisedAnnualRev: number): number {
    return revisedAnnualRev - recAnnualRev;
}

export function calculateWeightedAverageMarginVariance(deltaAnnualRevenue: number, recAnnualRev: number): number {
    return deltaAnnualRevenue / recAnnualRev || 0;
}

export function buildChildModelPayload(quote: IQuote, selectedProducs: [GridRowId, ProductForm][]) {
    const modelPayload: CalculationPayload<IRecommendedMarginsPayload> = {
        modelInputs: {
            customerName: (quote.customerName || '').toUpperCase(),
            customerTier: (quote.tier || '').toUpperCase(),
            newOrExisting: (quote.newOrExisting || '').toUpperCase(),
            transactionType: (quote.contractOrSpotBuy || '').toUpperCase(),
            competitiveness: quote.competitiveness ? COMPETITIVENESS_MODEL_MAP[quote.competitiveness] : '',
            quoteSize: quote.quoteSizeAdder ? quote.quoteSizeAdder : '',
            labelSpendShare: quote.shareOfCustomersLabelSpend
                ? LABEL_SPEND_SHARE_MODEL_MAP[quote.shareOfCustomersLabelSpend]
                : '',
            lineParams: [],
        },
    };

    selectedProducs.forEach((row: [GridRowId, ProductForm], index: number) => {
        const productRow = row[1];
        const newLine: IRecommendedMarginsLineParamsPayload = {
            lineId: index,
            productNumber: productRow.number,
            productDescription: (productRow.name || '').toUpperCase(),
            endMarket: (productRow.endMarket || '').toUpperCase(),
            printTech: (productRow.printingTechnology || '').toUpperCase(),
            decTech: (productRow.decoratingTechnology || '').toUpperCase(),
            substrate: (productRow.substrate || '').toUpperCase(),
            plantName: (productRow.recommendedPlant || '').toUpperCase(),
            brand: (productRow.brand || '').toUpperCase(),
            labelComplexity: productRow.labelComplexity ? LABEL_COMPLEXITY_MODEL_MAP[productRow.labelComplexity] : '',
            existingLabelSimilarity: productRow.similarityToCustomersExistingLabel
                ? EXISTING_LABEL_SIMILARITY_MODEL_MAP[productRow.similarityToCustomersExistingLabel]
                : '',
            businessUnit: productRow.businessUnit,
        };
        modelPayload.modelInputs.lineParams.push(newLine);
    });

    return modelPayload;
}

export const getDivisionEnumKey = (division: string): TDivisionKeys => {
    switch (division) {
        case 'F&B':
            return 'FANDB';
        case 'HPC':
            return 'HPC';
        case 'W&S':
        default:
            return 'WANDS';
    }
};

export const getDivisionByPermission = (division: string): string => {
    switch (division) {
        case 'mcc.fandb':
            return DivisionEnum.FANDB;
        case 'mcc.hpc':
            return DivisionEnum.HPC;
        case 'mcc.wands':
        default:
            return DivisionEnum.WANDS;
    }
};

export const getERPByPermission = (erp: string): string => {
    switch (erp) {
        case 'mcc.radius':
            return ERPEnum.RADIUS;
        case 'mcc.globetek':
        default:
            return ERPEnum.GLOBETEK;
    }
};

export function getExcelExportData(quote: IQuote): IQuoteExcelExport {
    return {
        quoteId: quote.quoteNumber || '',
        customerName: quote.customerName || '',
        tier: quote.tier || '',
        achievableSpend: quote.achievableSpend || '',
        date: formatDate(new Date(quote.date)) || '',
        salesRepName: quote.salesRepName || '',
        products: quote.products.map(product => {
            const recommendedPricePerUnit =
                quote.erp === ERPEnum.RADIUS && quote.division === DivisionEnum.WANDS
                    ? Number(
                          formatToDecimals(Number(product.estimatedCostPerUnit) * (1 + Number(product.targetMargin)), 4)
                      )
                    : Number(
                          formatToDecimals(Number(product.estimatedCostPerUnit) / (1 - Number(product.targetMargin)), 4)
                      );
            const revisedPricePerUnit =
                Number(product.finalMargin) === 1
                    ? '0.0000'
                    : quote.erp === ERPEnum.RADIUS && quote.division === DivisionEnum.WANDS
                    ? Number(
                          formatToDecimals(Number(product.estimatedCostPerUnit) * (1 + Number(product.finalMargin)), 4)
                      )
                    : Number(
                          formatToDecimals(Number(product.estimatedCostPerUnit) / (1 - Number(product.finalMargin)), 4)
                      );
            const dmtVariance = Number(product.finalMargin) - Number(product.targetMargin) || 0;
            return {
                productNumber: product.number || '',
                description: product.name || '',
                annualQuantity: Number(product.estimatedAnnualQuantity) || '',
                costPerUnit: Number(formatToDecimals(Number(product.estimatedCostPerUnit), 4)) || '',
                businessUnit: product.businessUnit || '',
                decTech:
                    product.decoratingTechnology && product.decoratingTechnology !== 'DO NOT USE - ROCHESTER ONLY'
                        ? product.decoratingTechnology
                        : product.substrate,
                printTech: product.printingTechnology || '',
                plant: product.recommendedPlant || '',
                labelComplexity: product.labelComplexity || '',
                existingLabelSimilarity: product.similarityToCustomersExistingLabel || '',
                endMarket: product.endMarket || '',
                baselineMargin: Number(product.baselineMargin) || '',
                dmtFloor: product.floorMargin || '',
                dmtTarget: Number(product.targetMargin) || '',
                recommendedPricePerUnit,
                finalDmt: Number(product.finalMargin) || '',
                revisedPricePerUnit,
                dmtVariance,
                quoteLineNumber: product.productKey || '',
            };
        }),
    };
}
