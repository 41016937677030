import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';
import { IDownloadFileProps } from 'shared/types';
import { useService } from '@insight2profit/drive-app';

export function useExcelExport<T>() {
    const { quoteService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const mutation = useMutation<void, Error, IDownloadFileProps<T>>({
        mutationFn: request => quoteService.downloadExcel(request),
        onSuccess: () => {
            enqueueSnackbar('Quote was exported', { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar('Quote was not exported', { variant: 'error' });
        },
    });

    return mutation;
}
