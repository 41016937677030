import { useService } from '@insight2profit/drive-app';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { QUERY_KEYS } from 'shared/constants';

export function useCustomerBusinessUnitSearch(user: app.UserInfo | null, isRadius: boolean, isWandS: boolean) {
    const { quoteService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const initialData: string[] = [];
    const { data, isFetching, isLoading } = useQuery<string[], Error>({
        queryKey: [QUERY_KEYS.businessUnit],
        queryFn: () =>
            quoteService.getCustomerBusinessUnits(user, isRadius, isWandS).then(data => data.map(d => d.division)),
        initialData,
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            return initialData;
        },
    });

    return { data, isFetching, isLoading } as const;
}
