import { Dialog, DialogContent, DialogContentText, CircularProgress, Box, DialogTitle } from '@mui/material';
import { TPleaseWaitAction } from 'shared/types';
import { PLEASE_WAIT_MODAL_STYLES, PRODUCT_DASHBOARD_MODAL_STYLES } from '../styles';

interface PleaseWaitModalProps {
    open: boolean;
    action: TPleaseWaitAction;
}

export function PleaseWaitModal({ open, action }: PleaseWaitModalProps) {
    return (
        <Dialog open={open} maxWidth={'xs'} fullWidth>
            <DialogTitle sx={PLEASE_WAIT_MODAL_STYLES.titleStyle}>Please wait</DialogTitle>
            <DialogContent>
                <DialogContentText sx={PRODUCT_DASHBOARD_MODAL_STYLES.subtitleStyle}>
                    {action === 'mass-uploads' ? 'The products are being uploaded' : 'The margins are being updated'}
                </DialogContentText>
                <Box sx={PLEASE_WAIT_MODAL_STYLES.subtitleStyle}>
                    <CircularProgress size={60} />
                </Box>
            </DialogContent>
        </Dialog>
    );
}
