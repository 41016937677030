import { Box, TextField } from '@mui/material';
import { FormattedNumber } from '@price-for-profit/data-grid';
import { RegisterOptions, useController } from 'react-hook-form';
interface NumberInputProps {
    name: string;
    label: string;
    disabled?: boolean;
    prefix?: string;
    suffix?: string;
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    defaultValue?: string | number;
    fullWidth?: boolean;
    startAdornment?: JSX.Element;
    endAdornment?: JSX.Element;
    decimalPlaces?: number;
    percent?: boolean;
    onChangeValue?: (value: string) => void;
}
export function NumberInput({
    name,
    rules,
    label,
    disabled,
    prefix,
    suffix,
    startAdornment,
    endAdornment,
    defaultValue,
    fullWidth = true,
    decimalPlaces = 2,
    percent,
    onChangeValue,
}: NumberInputProps) {
    const {
        field: { onChange, ref, value },
        fieldState: { error },
    } = useController({
        name,
        defaultValue,
        rules,
    });
    const fieldValue = percent ? value * 100 : value;

    return (
        <Box>
            <FormattedNumber
                id={name}
                displayType='input'
                customInput={TextField}
                onValueChange={v => {
                    const numberValue = v.floatValue || Number(v.value);
                    let newValue = '';
                    if (v.value) {
                        newValue = (percent ? numberValue / 100 : numberValue).toString();
                    }
                    onChange(newValue);
                    onChangeValue?.(newValue);
                }}
                value={fieldValue}
                error={Boolean(error)}
                helperText={error?.message}
                disabled={disabled}
                inputRef={ref}
                inputProps={{
                    'aria-label': name,
                }}
                fullWidth={fullWidth}
                label={`${label + (!!rules?.required ? ' (Required)' : ' (Optional)')}`}
                prefix={prefix}
                suffix={suffix}
                InputProps={{
                    startAdornment,
                    endAdornment,
                }}
                decimalScale={decimalPlaces}
                InputLabelProps={{ shrink: true }}
            />
        </Box>
    );
}
