export const QUERY_KEYS = {
    quote: 'quote',
    singleQuote: 'single-quote',
    customer: 'customer',
    customerOverride: 'customer-override',
    competitor: 'competitor',
    customerTiers: 'customer-tier',
    salesRepName: 'sales-rep-name',
    businessUnit: 'business-unit',
    endMarket: 'end-market',
    product: 'product',
    productScatter: 'product-scatter',
    productHistogram: 'product-histogram',
    recommendedPlant: 'recommended-plant',
    decoratingTechnology: 'decorating-technology',
    printingTechnology: 'printing-technology',
    substrates: 'substrates',
    brands: 'brands',
    quoteSizeAdder: 'quote-size-adder',
} as const;
