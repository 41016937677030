import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useWatch } from 'react-hook-form';
import { ProductForm, ERPEnum, DivisionEnum } from 'shared/types';
import { formatMoneyNoDecimals, formatPercent } from 'shared/utility';
import { usePermissionERPDivisionState } from 'shared/contexts';

interface IMarginVarianceProps {
    dialogOpen: boolean;
    setDialogOpen: (param: boolean) => void;
}

export function MarginVariance({ dialogOpen, setDialogOpen }: IMarginVarianceProps) {
    const watchProducts = useWatch({ name: 'products' }) as ProductForm[];
    const watchErp = useWatch({ name: 'erp' });
    const watchDivision = useWatch({ name: 'division' });
    const [series, setSeries] = useState<any[]>([]);
    const [erp, setErp] = useState<string>('');
    const [division, setDivision] = useState<string>('');
    const [options, setOptions] = useState<ApexOptions>({
        xaxis: {
            type: 'numeric',
            min: 0,
            labels: {
                formatter: function (value: any) {
                    return formatMoneyNoDecimals(value);
                },
            },
            title: {
                text: 'Sum of Actual Revenue ',
                style: {
                    fontFamily: 'roboto',
                    fontSize: '16px',
                },
            },
        },
        yaxis: {
            title: {
                text: 'Sum of Final DMT %',
                style: {
                    fontFamily: 'roboto',
                    fontSize: '16px',
                },
            },
            min: 0,
            max: 1,
            labels: {
                formatter: function (value: any) {
                    return formatPercent(value);
                },
            },
        },
        chart: {
            height: 'auto',
            stacked: false,
            zoom: {
                enabled: true,
            },
            events: {
                beforeZoom: (e, { xaxis }) => {
                    if (xaxis.min < 0) {
                        return {
                            xaxis: {
                                min: 0,
                            },
                        };
                    }
                },
            },
        },
        noData: {
            text: 'No historical data available',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
        },
    });
    const { divisionEnabled, erpEnabled } = usePermissionERPDivisionState();

    function getTargetUnitPrice(product: ProductForm, erp: string, division: string): number {
        if (erp === ERPEnum.RADIUS && division === DivisionEnum.WANDS) {
            return +product.estimatedCostPerUnit * (1 + +product.targetMargin);
        } else {
            return +product.estimatedCostPerUnit / (1 - +product.targetMargin);
        }
    }

    function getTargetMargin(product: ProductForm, targetUnitPrice: number): number {
        return (targetUnitPrice - +product.estimatedCostPerUnit) * +product.estimatedAnnualQuantity;
    }

    function getFinalMargin(product: ProductForm, finalUnitPrice: number): number {
        return (finalUnitPrice - +product.estimatedCostPerUnit) * +product.estimatedAnnualQuantity;
    }

    function getRevisedPricePerUnit(product: ProductForm, erp: string, division: string) {
        return product.revisedPricePerUnit
            ? Number(product.revisedPricePerUnit)
            : Number(product.finalMargin) === 1
            ? 0
            : erp === ERPEnum.RADIUS && division === DivisionEnum.WANDS
            ? Number(product.estimatedCostPerUnit) * (1 + Number(product.finalMargin))
            : Number(product.estimatedCostPerUnit) / (1 - Number(product.finalMargin));
    }

    useEffect(() => {
        const erp = watchErp || erpEnabled;
        const division = watchDivision || divisionEnabled;

        setDivision(division);
        setErp(erp);
    }, [divisionEnabled, erpEnabled, watchErp, watchDivision]);

    useEffect(() => {
        const data = watchProducts.map(product => {
            const targetUnitPrice = getTargetUnitPrice(product, erp, division);
            const targetRevenue = targetUnitPrice * +product.estimatedAnnualQuantity;
            const finalUnitPrice = getRevisedPricePerUnit(product, erp, division);
            const finalRevenue = finalUnitPrice * +product.estimatedAnnualQuantity;
            return {
                marginVariation: +product.finalMargin,
                targetRevenue,
                targetUnitPrice,
                estimatedCostPerUnit: +product.estimatedCostPerUnit,
                targetMargin: getTargetMargin(product, targetUnitPrice),
                finalRevenue,
                finalMargin: getFinalMargin(product, finalUnitPrice),
                decoratingTechnology: product.decoratingTechnology?.toUpperCase(),
                printingTechnology: product.printingTechnology?.toUpperCase(),
            };
        });

        const groupByDecTechPrinTech = data.reduce((group, product) => {
            const { decoratingTechnology, printingTechnology } = product;
            const key = `${decoratingTechnology} | ${printingTechnology}`;
            group[key] = group[key] ?? [];
            group[key].push(product);
            return group;
        }, {} as any);

        const series = [];
        for (const property in groupByDecTechPrinTech) {
            series.push({
                name: property,
                type: 'scatter',
                data: groupByDecTechPrinTech[property].map((data: any) => ({
                    x: data.finalRevenue,
                    y: data.marginVariation,
                })),
            });
        }

        setSeries(series);

        const targetRevenue = data.reduce(
            (total, data) => {
                total.targetRevenueTotal += data.targetRevenue;
                total.targetMarginTotal += data.targetMargin;
                total.finalRevenueTotal += data.finalRevenue;
                total.finalMarginTotal += data.finalMargin;
                return total;
            },
            { targetRevenueTotal: 0, targetMarginTotal: 0, finalRevenueTotal: 0, finalMarginTotal: 0 }
        );
        const targetMarginLine = targetRevenue.targetMarginTotal / targetRevenue.targetRevenueTotal;
        const finalMarginLine = targetRevenue.finalMarginTotal / targetRevenue.finalRevenueTotal;

        setOptions(options => ({
            ...options,
            annotations: {
                yaxis: [
                    {
                        y: targetMarginLine,
                        borderColor: '#3e3eab',
                        borderWidth: 3,
                        opacity: 0,
                        strokeDashArray: 4,
                        label: {
                            style: {
                                color: '#3e3eab',
                                fontFamily: 'roboto',
                                fontWeight: 'bold',
                            },
                            text: `Target Margin ${formatPercent(targetMarginLine)}`,
                        },
                    },
                    {
                        y: finalMarginLine,
                        borderColor: '#a13838',
                        borderWidth: 3,
                        opacity: 0,
                        strokeDashArray: 4,
                        label: {
                            style: {
                                color: '#a13838',
                                fontFamily: 'roboto',
                                fontWeight: 'bold',
                            },
                            text: `Average  Final Margin ${formatPercent(finalMarginLine)}`,
                        },
                    },
                ],
            },
        }));
    }, [watchProducts, erp, division]);

    return (
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth={'lg'} fullWidth>
            <DialogTitle>
                Sum of Actual Revenue and Sum of Final DMT% by Quote Line# and Print Tech Dech Tech
            </DialogTitle>
            <DialogContent>
                <ReactApexChart options={options} series={series} type='scatter' height={350} />
            </DialogContent>
        </Dialog>
    );
}
