import {
    AxiosInstance,
    CalculationService,
    DataAccessService,
    EventService,
    ExportService,
} from '@price-for-profit/micro-services';
import { NotificationService, QuoteService, ProductService } from 'shared/services';
import { AppConfig } from 'shared/types';

export function createServiceContext(appConfig: AppConfig, httpClient: AxiosInstance): app.ServiceContext<AppConfig> {
    const eventService = new EventService(httpClient);
    const dasService = new DataAccessService(httpClient);
    const calculationService = new CalculationService(httpClient);
    const notificationService = new NotificationService(eventService);
    const exportService = new ExportService(httpClient);

    const quoteService = new QuoteService(calculationService, dasService, notificationService, exportService);
    const productService = new ProductService(dasService);
    return {
        appConfig,
        eventService,
        notificationService,
        quoteService,
        productService,
    };
}
