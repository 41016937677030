import { Save, Send } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';

interface ISaveQuoteButtons {
    showSaveButton?: boolean;
    showSubmitButton?: boolean;
    onSaveButtonClick: () => void;
    onSubmitButtonClick: () => void;
}

export function SaveQuoteButtons({
    showSaveButton = true,
    showSubmitButton = true,
    onSaveButtonClick,
    onSubmitButtonClick,
}: ISaveQuoteButtons) {
    return (
        <>
            {showSaveButton && (
                <Grid item>
                    <Button startIcon={<Save />} type='submit' variant='contained' onClick={onSaveButtonClick}>
                        Save Quote
                    </Button>
                </Grid>
            )}
            {showSubmitButton && (
                <Grid item>
                    <Button startIcon={<Send />} type='submit' variant='contained' onClick={onSubmitButtonClick}>
                        Submit Quote
                    </Button>
                </Grid>
            )}
        </>
    );
}
