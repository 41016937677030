import { useUser } from '@insight2profit/drive-app';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { GridSelect } from 'shared/components/datagrid/grid-select';
import { usePermissionERPDivisionState } from 'shared/contexts';
import { useSubstrateSearch } from 'shared/queries/substrate-search';
import { DivisionEnum, ERPEnum, Product } from 'shared/types';
import { getDivisionByPermission, getERPByPermission } from 'shared/utility';

export function SubstrateGridSelect(params: GridRenderCellParams<string>) {
    const user = useUser();

    const { divisionEnabled, erpEnabled } = usePermissionERPDivisionState();

    const isRadius = getERPByPermission(erpEnabled) === ERPEnum.RADIUS;
    const isWandS = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.WANDS);
    const isFandB = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.FANDB);

    const businessUnit = (params.row as Product).businessUnit;
    const { data, isLoading, isFetching } = useSubstrateSearch(businessUnit, user, isRadius, isFandB, isWandS);

    if (params.row.recommendedPlant.toUpperCase() === 'ROCHESTER') {
        return <span>{params.value}</span>;
    }

    return (
        <GridSelect
            id={'substrateGridSelect'}
            data={data}
            isLoading={isLoading}
            isFetching={isFetching}
            params={params}
        />
    );
}
