import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import {
    DataGridPremium,
    GridCellEditCommitParams,
    GridColumns,
    GridRowClassNameParams,
    GridRowId,
    MuiEvent,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import { v4 as uuid } from 'uuid';
import { useCallback, useMemo, useState } from 'react';
import {
    FieldValues,
    UseFieldArrayAppend,
    UseFieldArrayRemove,
    UseFieldArrayUpdate,
    useFormContext,
} from 'react-hook-form';
import { useProductFormMethods } from 'shared/hooks';
import {
    CSVProduct,
    ERPEnum,
    IQuote,
    IRecommendedMarginsPayload,
    IRecommendedMarginsResponse,
    MassActionForm,
    ProductForm,
    ProductSummaryForm,
    QuoteStatusEnum,
    ReviewedEnum,
    TProductFormWithId,
    TQuoteWithoutId,
} from 'shared/types';
import {
    buildChildModelPayload,
    calculateRecommendedAnnualRevenue,
    calculateRevisedAnnualRevenue,
    formatObjectValuesToUppercase,
    getDivisionByPermission,
    getERPByPermission,
} from 'shared/utility';
import { productColumns } from './columns';
import { ProductsTableToolbar } from './table-toolbar';
import { ProductAddModal } from './modal/add-modal';
import { DashboardModal } from './modal/dashboard-modal';
import { useCreateQuote, useExecuteModel, useUpdateQuote } from 'shared/mutations';
import { CALCULATION_MODELS, QUOTE_NUMBER_SEED } from 'shared/constants';
import { PRODUCT_SUMMARY_STYLES } from './styles';
import { MassUploadButtons } from './mass-upload-buttons';
import { useCreateProductErrorData } from 'shared/mutations/create-product-error-data';
import { PleaseWaitModal } from './modal/please-wait-modal';
import { useService, useUser } from '@insight2profit/drive-app';
import { usePermissionERPDivisionState } from 'shared/contexts';

interface ProductSummaryProps {
    rows: ProductForm[];
    append: UseFieldArrayAppend<FieldValues, 'products'>;
    remove: UseFieldArrayRemove;
    updateProductRow: UseFieldArrayUpdate<FieldValues, 'products'>;
}

export function ProductSummary({ rows, append, remove, updateProductRow }: ProductSummaryProps) {
    const { setValue, watch, getValues } = useFormContext();
    const endMarket = watch('endMarket');
    const { copyProduct, methods, removeProduct, resetProductMethods, setProductKey } = useProductFormMethods({
        append,
        remove,
        rows,
    });

    const apiRef = useGridApiRef();
    const user = useUser();
    const { divisionEnabled, erpEnabled } = usePermissionERPDivisionState();

    const executeMutation = useExecuteModel<IRecommendedMarginsPayload, IRecommendedMarginsResponse>(
        CALCULATION_MODELS.recommendedMargin
    );
    const createProductErrorDataMutation = useCreateProductErrorData();
    const [newProductDialogOpen, setNewProductDialogOpen] = useState<boolean>(false);
    const [dashboardOpen, setDashboardOpen] = useState<boolean>(false);
    const [isLoadingCalc, setIsLoadingCalc] = useState<boolean>(false);
    const [productDashboard, setProductDashboard] = useState<TProductFormWithId | null>(null);
    const [productToEdit, setProductToEdit] = useState<TProductFormWithId | null>(null);
    const [invalidProducts, setInvalidProducts] = useState<CSVProduct[]>([]);

    const watchProducts = watch('products') as ProductForm[];
    const watchQuoteNumber = watch('quoteNumber') as number;

    const { quoteService } = useService();
    const createQuoteMutation = useCreateQuote();
    const updateQuoteMutation = useUpdateQuote(undefined, false);

    const getMargins = useCallback(
        async (selectedProducts: [GridRowId, any][]) => {
            const modelPayload = buildChildModelPayload({ ...getValues() } as IQuote, selectedProducts);
            setIsLoadingCalc(true);
            try {
                const { data } = await executeMutation.mutateAsync(modelPayload);
                selectedProducts.forEach((row: [GridRowId, ProductForm], index: number) => {
                    const productRow = row[1];
                    const productCalcLine = data.callMarginLineModel[index];

                    const hasError = productCalcLine?.baseline === -100;
                    productRow.floorMargin = hasError ? '-100' : productCalcLine?.finalMarginFloor.toFixed(4);
                    productRow.targetMargin = hasError ? '-100' : productCalcLine?.recommendation.toFixed(4);
                    productRow.peerGroup = productCalcLine.peerGroup;
                    productRow.baselineKey = productCalcLine.baselineKey;
                    productRow.peerGroupPercentile = (hasError
                        ? '-100'
                        : productCalcLine?.peerGroupPercentile || 0
                    ).toString();
                    productRow.finalMargin = (hasError
                        ? ''
                        : productCalcLine?.recommendation.toFixed(4) || 0
                    ).toString();
                    productRow.baselineMargin = (hasError ? '-100' : productCalcLine?.baseline.toFixed(4)).toString();
                    productRow.isReviewed = ReviewedEnum.NO;
                    watchProducts[watchProducts.findIndex(r => r.uniqueId === productRow.uniqueId)] = productRow;
                });
                setValue('products', watchProducts);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoadingCalc(false);
            }
        },
        [watchProducts, setValue, getValues, executeMutation, setIsLoadingCalc]
    );

    const onCloseAddProduct = useCallback(() => {
        setNewProductDialogOpen(false);
        setProductToEdit(null);
    }, [setNewProductDialogOpen, setProductToEdit]);

    const onCloseDashboard = useCallback(() => {
        setDashboardOpen(false);
        setProductDashboard(null);
    }, [setDashboardOpen, setProductDashboard]);

    const addProduct = useCallback(() => {
        setNewProductDialogOpen(true);
        resetProductMethods();
    }, [setNewProductDialogOpen, resetProductMethods]);

    const editProduct = useCallback(
        (item: TProductFormWithId) => {
            resetProductMethods();
            setProductToEdit(item);
            setNewProductDialogOpen(true);
        },
        [setProductToEdit, setNewProductDialogOpen, resetProductMethods]
    );

    const updateIsReviewedOnFinalMarginChange = useCallback(
        (index: number, value: ReviewedEnum) => {
            setValue(`products.${index}.isReviewed`, value);
        },
        [setValue]
    );

    const updateFinalMarginOnRevisedPriceChange = useCallback(
        (index: number, finalMargin: string, revisedPrice: string) => {
            setValue(`products.${index}.finalMargin`, finalMargin);
            setValue(`products.${index}.revisedPricePerUnit`, revisedPrice);
        },
        [setValue]
    );

    const openDashboard = useCallback(
        (item: TProductFormWithId) => {
            setProductDashboard(item);
            setDashboardOpen(true);
        },
        [setProductDashboard, setDashboardOpen]
    );

    const handleAddMassUploadedProducts = useCallback(
        (products: ProductForm[]) => {
            setValue('products', [...watchProducts, ...products]);
        },
        [setValue, watchProducts]
    );

    const handleInvalidProducts = useCallback(
        async (invalidProducts: CSVProduct[]) => {
            setInvalidProducts(invalidProducts);
            if (invalidProducts.length) {
                await createProductErrorDataMutation.mutateAsync({
                    createdBy: user!.displayName,
                    createdOn: new Date().toISOString(),
                    productsWithErrors: invalidProducts,
                });
            }
        },
        [createProductErrorDataMutation, user]
    );

    const handleProductsUploadComplete = useCallback(
        async (validProducts: ProductForm[], invalidProducts: CSVProduct[]) => {
            handleAddMassUploadedProducts(validProducts);
            await handleInvalidProducts(invalidProducts);
        },
        [handleAddMassUploadedProducts, handleInvalidProducts]
    );

    const submitProduct = useCallback(
        (form: ProductSummaryForm) => {
            const addedProduct = form.products[0];

            if (
                addedProduct.number &&
                rows.some(
                    p => p.number === addedProduct.number && p.name.toUpperCase() !== addedProduct.name.toUpperCase()
                )
            ) {
                methods.setError(`products.${0}.number`, {
                    type: 'custom',
                    message: 'Product already exists in the quote with a different name',
                });
                return;
            }

            if (productToEdit) {
                const index = watchProducts.findIndex(r => r.uniqueId === productToEdit.uniqueId);
                updateProductRow(index, addedProduct);
            } else {
                addedProduct.uniqueId = uuid();
                setProductKey({
                    productToSetKey: addedProduct,
                    products: watchProducts,
                    quoteNumber: watchQuoteNumber,
                });
                append(addedProduct);
            }
            resetProductMethods();
            onCloseAddProduct();
        },
        [
            rows,
            productToEdit,
            watchProducts,
            watchQuoteNumber,
            append,
            methods,
            onCloseAddProduct,
            resetProductMethods,
            updateProductRow,
            setProductKey,
        ]
    );

    const confirmMassActions = useCallback(
        (form: MassActionForm) => {
            const selectedModels = [...apiRef.current.getSelectedRows()];
            const filteredModels =
                selectedModels.length > 0 ? selectedModels : [...apiRef.current.getVisibleRowModels()];
            filteredModels.forEach(productRow => {
                const index = rows.findIndex(r => r.uniqueId === productRow[1].uniqueId);
                let valueToSet = productRow[1].finalMargin;
                const typeSelected = form.type?.type;
                switch (typeSelected) {
                    case 'floor':
                        valueToSet = productRow[1].floorMargin;
                        break;
                    case 'target':
                        valueToSet = productRow[1].targetMargin;
                        break;
                    case 'override':
                        valueToSet = form.change.toString();
                        break;
                    default:
                        valueToSet = productRow[1].finalMargin;
                        break;
                }
                setValue(`products.${index}.finalMargin`, valueToSet);
                setValue(`products.${index}.isReviewed`, ReviewedEnum.YES);
            });
        },
        [apiRef, setValue, rows]
    );

    const setProductKeyToSelectedProduct = useCallback(
        (products: ProductForm[], productRow: ProductForm, quoteNumber: number) => {
            if (!productRow.productKey) {
                setProductKey({ productToSetKey: productRow, products, quoteNumber });
                const index = products.findIndex(p => p.uniqueId === productRow.uniqueId) ?? -1;
                if (index !== -1) {
                    setValue(`products.${index}.productKey`, productRow.productKey);
                    products[index].productKey = productRow.productKey;
                }
            }
        },
        [setProductKey, setValue]
    );

    // generateProductKey on edit quote page
    const generateProductKey = useCallback(
        async (selectedProducts: [GridRowId, any][]) => {
            const quote = getValues() as TQuoteWithoutId;
            if (quote.isSavedAsDraft) {
                return;
            }
            selectedProducts.forEach((row: [GridRowId, ProductForm]) => {
                setProductKeyToSelectedProduct([...watchProducts], row[1], watchQuoteNumber);
            });
        },
        [getValues, watchProducts, watchQuoteNumber, setProductKeyToSelectedProduct]
    );

    // generate productKey in new quote page
    const createProductKeyForNewQuote = useCallback(
        async (selectedProducts: [GridRowId, any][]) => {
            const quote = getValues() as IQuote;
            if (!quote.isSavedAsDraft) {
                return;
            }

            if (quote.id && quote.quoteNumber) {
                selectedProducts.forEach((row: [GridRowId, ProductForm]) => {
                    // had to repeat this 'if' cuz of typescript linter
                    if (quote.quoteNumber) {
                        setProductKeyToSelectedProduct([...watchProducts], row[1], quote.quoteNumber);
                    }
                });
                return;
            }

            // create a quote to generateProductKey
            try {
                formatObjectValuesToUppercase(quote);
                const revisedAnnualRevenue = calculateRevisedAnnualRevenue(
                    quote.products,
                    getERPByPermission(erpEnabled),
                    getDivisionByPermission(divisionEnabled[0])
                );
                const recommendedAnnualRevenue = calculateRecommendedAnnualRevenue(
                    quote.products,
                    getERPByPermission(erpEnabled),
                    getDivisionByPermission(divisionEnabled[0])
                );

                const newQuote: TQuoteWithoutId = {
                    ...quote,
                    createdBy: user?.displayName.toUpperCase() || '',
                    createdOn: new Date().toISOString(),
                    erp: getERPByPermission(erpEnabled),
                    division: getDivisionByPermission(divisionEnabled[0]),
                    status: QuoteStatusEnum.DRAFT,
                    reviewedByNotes: '',
                    revisedAnnualRevenue,
                    recommendedAnnualRevenue,
                    deltaAnnualRevenue: revisedAnnualRevenue - recommendedAnnualRevenue,
                };

                const initialQuoteCreated = await createQuoteMutation.mutateAsync(newQuote);
                const recentQuotesResponse = await quoteService.getPreviousQuotes(initialQuoteCreated);
                const newQuoteNumber = QUOTE_NUMBER_SEED + recentQuotesResponse.metadata.totalCount;
                initialQuoteCreated.quoteNumber = newQuoteNumber;

                setValue('quoteNumber', newQuoteNumber);
                setValue('id', initialQuoteCreated.id);

                const upperCasedProducts = watchProducts.map(p => ({ ...p, uniqueId: p.uniqueId.toUpperCase() }));
                selectedProducts.forEach(row => {
                    const productRow = row[1];
                    const productIndex =
                        initialQuoteCreated.products.findIndex(
                            item => item.uniqueId === productRow.uniqueId.toUpperCase()
                        ) ?? -1; // finding index cuz we don't know the order after save
                    if (productIndex !== -1) {
                        setProductKeyToSelectedProduct(
                            upperCasedProducts,
                            initialQuoteCreated.products[productIndex],
                            newQuoteNumber
                        );
                    }
                });
                await updateQuoteMutation.mutateAsync(initialQuoteCreated);
            } catch (e) {
                console.log(e);
            }
        },
        [
            divisionEnabled,
            user,
            erpEnabled,
            watchProducts,
            getValues,
            setValue,
            createQuoteMutation,
            quoteService,
            updateQuoteMutation,
            setProductKeyToSelectedProduct,
        ]
    );

    const columns = useMemo<GridColumns<ProductForm>>(
        () =>
            productColumns({
                remove: removeProduct,
                copyProduct,
                editProduct,
                openDashboard,
                updateIsReviewedOnFinalMarginChange,
                updateFinalMarginOnRevisedPriceChange,
                erp: watch('erp') || getERPByPermission(erpEnabled),
                division: watch('division') || getDivisionByPermission(divisionEnabled[0]),
            }),
        [
            divisionEnabled,
            erpEnabled,
            watch,
            removeProduct,
            copyProduct,
            editProduct,
            openDashboard,
            updateIsReviewedOnFinalMarginChange,
            updateFinalMarginOnRevisedPriceChange,
        ]
    );

    return (
        <>
            <Box height='calc(80vh - 215px)' sx={PRODUCT_SUMMARY_STYLES.dataGridBoxContainerStyle}>
                <DataGridPremium
                    checkboxSelection
                    disableSelectionOnClick
                    columns={columns}
                    rows={rows}
                    apiRef={apiRef}
                    sx={PRODUCT_SUMMARY_STYLES.dataGridStyle}
                    columnVisibilityModel={{
                        substrate: (watch('erp') || erpEnabled) === ERPEnum.GLOBETEK,
                    }}
                    getRowClassName={(params: GridRowClassNameParams<ProductForm>) =>
                        Number(params.row.baselineMargin) - Number(params.row.peerGroupPercentile) > 0.1
                            ? 'high-margin'
                            : ''
                    }
                    components={{
                        Toolbar: () => (
                            <ProductsTableToolbar
                                isDisabledGetMargins={isLoadingCalc}
                                confirmMassActions={confirmMassActions}
                                getMargins={getMargins}
                                generateProductKey={generateProductKey}
                                createProductKeyForNewQuote={createProductKeyForNewQuote}
                            />
                        ),
                        Footer: () => (
                            <Box sx={{ padding: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                <Box display='flex'>
                                    <Button startIcon={<Add />} onClick={addProduct} variant='text'>
                                        Add Product
                                    </Button>
                                    <MassUploadButtons
                                        setProductKey={setProductKey}
                                        watchProducts={watchProducts}
                                        watchQuoteNumber={watchQuoteNumber}
                                        user={user}
                                        handleParseComplete={handleProductsUploadComplete}
                                        invalidProducts={invalidProducts}
                                        setInvalidProducts={setInvalidProducts}
                                    />
                                </Box>
                                <span style={{ fontSize: '.9em' }}>Final Margins must be between 0% and 100%</span>
                            </Box>
                        ),
                    }}
                    onCellEditCommit={(params: GridCellEditCommitParams, event: MuiEvent) => {
                        const model = apiRef.current.getRowParams(params.id);
                        const index = watchProducts.findIndex(r => r.uniqueId === model.row.uniqueId);
                        setValue(`products.${index}.${params.field}`, params.value);
                    }}
                />
            </Box>
            {isLoadingCalc && <PleaseWaitModal open={isLoadingCalc} action={'calculate-margins'} />}
            {newProductDialogOpen && (
                <ProductAddModal
                    methods={methods}
                    open={newProductDialogOpen}
                    onClose={onCloseAddProduct}
                    submitProduct={submitProduct}
                    defaultEndMarketProduct={endMarket}
                    productToEdit={productToEdit || undefined}
                />
            )}
            {dashboardOpen && (
                <DashboardModal
                    open={dashboardOpen}
                    onClose={onCloseDashboard}
                    product={productDashboard}
                    quoteProducts={watchProducts.filter(p => p.peerGroup === productDashboard?.peerGroup)}
                />
            )}
        </>
    );
}
