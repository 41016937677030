import { IProductMarginHistogram } from 'shared/types';
import { formatPercent, getFactors } from 'shared/utility';

export function graphBuilder(data: IProductMarginHistogram[]) {
    if (data.length > 0) {
        const sortedData = data
            .filter(d => Math.round(d.ttmrevenue) > 0)
            .sort((a, b) => {
                if (Number(a.marginpct) > Number(b.marginpct)) return 1;
                if (Number(a.marginpct) < Number(b.marginpct)) return -1;
                return 0;
            });

        let labels: string[] = [];
        const lastData = Number(sortedData[sortedData.length - 1].marginpct);

        if (sortedData.length > 20) {
            const xFactor = getFactors(Math.round(lastData * 100))[1][1];
            sortedData
                .map(b => Math.round(Number(b.marginpct) * 100))
                .forEach((d, i) => {
                    if (i % xFactor === 0) {
                        labels.push(d + '%');
                    } else {
                        if (i === sortedData.length - 1) {
                            labels.push(d + '%');
                        } else {
                            labels.push('');
                        }
                    }
                });
        } else {
            labels = [...sortedData.map(p => Math.round(Number(p.marginpct) * 100) + '%')];
        }

        const series = [
            {
                name: 'Revenue',
                type: 'column',
                data: [...sortedData.map(p => Math.round(p.ttmrevenue))],
            },
            {
                name: '% of Revenue',
                type: 'line',
                data: [...sortedData.map(p => formatPercent(p.pctofrevenue))],
            },
            {
                name: 'Margin %',
                type: 'area',
                data: [...sortedData.map(p => Math.round(Number(p.marginpct) * 100))],
            },
        ];

        return { series, labels, maxRevenue: Math.max(...sortedData.map(p => Math.round(p.ttmrevenue))) };
    }
    return { series: [], labels: [], maxRevenue: 100000 };
}
