import { useUser } from '@insight2profit/drive-app';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useGridApiContext, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { usePermissionERPDivisionState } from 'shared/contexts';
import { useDecoratingTechnologySearch } from 'shared/queries';
import { DivisionEnum, ERPEnum, Product } from 'shared/types';
import { getDivisionByPermission, getERPByPermission } from 'shared/utility';

export function DecoratingTechnologyGridSelect(params: GridRenderCellParams<string>) {
    const user = useUser();
    const { divisionEnabled, erpEnabled } = usePermissionERPDivisionState();

    const isRadius = getERPByPermission(erpEnabled) === ERPEnum.RADIUS;
    const isGlobeTek = getERPByPermission(erpEnabled) === ERPEnum.GLOBETEK;
    const isWandS = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.WANDS);
    const isFandB = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.FANDB);

    const businessUnit = (params.row as Product).businessUnit;
    const apiRef = useGridApiContext();
    const [inputValue, setInputValue] = useState(params.value);

    const { data, isLoading, isFetching } = useDecoratingTechnologySearch(
        businessUnit,
        user,
        isRadius,
        isFandB,
        isWandS
    );

    const handleValueChange = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
        apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: newValue });
    };

    const handleInputChange = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
        setInputValue(newValue);
    };

    if (isGlobeTek && params.row.recommendedPlant.toUpperCase() !== 'ROCHESTER') {
        return <span>{params.value}</span>;
    }

    if (data !== undefined || isLoading || isFetching) {
        return (
            <Autocomplete
                id='autocomplete'
                options={data || []}
                getOptionLabel={option => (option || '').toUpperCase()}
                sx={{ width: 300 }}
                size='small'
                defaultValue={inputValue}
                onChange={handleValueChange}
                onInputChange={handleInputChange}
                renderInput={params => <TextField {...params} />}
            />
        );
    } else {
        return <Box>Loading...</Box>;
    }
}
