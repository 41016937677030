import { useWatch } from 'react-hook-form';
import { NumberInput } from 'shared/components/forms/controls';
import { MassActionSelection } from 'shared/types';

const marginComponentMap: Record<MassActionSelection['type'], JSX.Element> = {
    override: (
        <NumberInput
            name='change'
            suffix='%'
            label='Override to Set'
            percent
            rules={{
                required: {
                    message: 'Override is required.',
                    value: true,
                },
                min: { value: 0.01, message: 'must be greater than 0%' },
                max: { value: 1.0, message: 'must be less or equal than 100%' },
            }}
        />
    ),
    floor: <></>,
    target: <></>,
};

export function DynamicMarginFields() {
    const selection: MassActionSelection = useWatch({ name: 'type' });
    if (!selection) return null;
    const component = marginComponentMap[selection.type];
    return component;
}
