import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { IRecommendedPlant } from 'shared/types';
import { QUERY_KEYS } from 'shared/constants';
import { useService } from '@insight2profit/drive-app';

export function useRecommendedPlantSearch(
    businessUnit: string,
    user: app.UserInfo | null,
    isRadius: boolean,
    isWandS: boolean
) {
    const { productService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const initialData: IRecommendedPlant[] = [];
    const { data, isFetching, isLoading } = useQuery<IRecommendedPlant[], Error>({
        queryKey: [QUERY_KEYS.recommendedPlant, businessUnit],
        queryFn: () => productService.getRecommendedPlants(businessUnit, user, isRadius, isWandS),
        initialData,
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            return initialData;
        },
    });

    return { data, isFetching, isLoading } as const;
}
