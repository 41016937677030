import { useGridApiContext } from '@mui/x-data-grid-premium';

export function useGridApiRows() {
    const apiRef = useGridApiContext();

    const selectedRows = [...apiRef.current.getSelectedRows()];

    const selectedRowIds = [...apiRef.current.getSelectedRows().keys()];

    const visibibleRows = [...apiRef.current.getVisibleRowModels()];

    const visibibleRowIds = [...apiRef.current.getVisibleRowModels().keys()];

    const clearSelectedRows = () => {
        apiRef.current.setSelectionModel([]);
    };

    return {
        selectedRows,
        visibibleRows,
        selectedRowIdsJoined: selectedRowIds.join(','),
        visibibleRowIdsJoined: visibibleRowIds.join(','),
        selectedRowsCount: selectedRows.length,
        visibleRowsCount: visibibleRows.length,
        clearSelectedRows,
    };
}
