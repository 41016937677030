import {
    TCompetitivenessKey,
    TLabelComplexityKey,
    TShareOfCustomersLabelSpendKey,
    TSimilarityToCustomersExistingLabelKey,
} from 'shared/types';

export const CALCULATION_MODELS = {
    recommendedMargin: 'recommendedMargin',
};

// Model maps are structure as AppValue: ModelValue
export const COMPETITIVENESS_MODEL_MAP: Record<TCompetitivenessKey, string> = {
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
};

export const EXISTING_LABEL_SIMILARITY_MODEL_MAP: Record<TSimilarityToCustomersExistingLabelKey, string> = {
    'LOW-TO-MODERATE': 'LOW TO MODERATE',
    HIGH: 'HIGH',
};

export const LABEL_COMPLEXITY_MODEL_MAP: Record<TLabelComplexityKey, string> = {
    'TCS-MACHINE': 'TCS MACHINE',
    HIGH: 'HIGH',
    MEDIUM: 'MEDIUM',
    LOW: 'LOW',
    'BLANK LABEL': 'BLANK LABEL',
};

export const LABEL_SPEND_SHARE_MODEL_MAP: Record<TShareOfCustomersLabelSpendKey, string> = {
    '100%-FOR-SEVERAL-YEARS': '100% OF SEVERAL YEARS',
    '100%-OF-BUSINESS-SPEND': '100% OF BUSINESS',
    'MAJORITY-OF-BUSINESS': 'MAJORITY OF BUSINESS',
    'MINORITY-OF-BUSINESS': 'MINORITY OF BUSINESS',
    NONE: 'NONE',
};
