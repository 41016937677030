import { useQuery } from '@tanstack/react-query';
import { IProductLookup } from 'shared/types';
import { useSnackbar } from 'notistack';
import { QUERY_KEYS } from 'shared/constants';
import { useService } from '@insight2profit/drive-app';

export function useProductSearch(
    businessUnit: string,
    user: app.UserInfo | null,
    query: string,
    isRadius: boolean,
    isFandB: boolean,
    isWandS: boolean
) {
    const { productService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const initialData: IProductLookup[] = [];
    const { data, isFetching, isLoading } = useQuery<IProductLookup[], Error>({
        queryKey: [QUERY_KEYS.product, query, businessUnit],
        queryFn: () => productService.getProductsData(businessUnit, user, query, isRadius, isFandB, isWandS),
        initialData,
        enabled: Boolean(query),
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            return initialData;
        },
    });

    return { data, isFetching, isLoading } as const;
}
