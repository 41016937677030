import { useUser, useDebounce } from '@insight2profit/drive-app';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { usePermissionERPDivisionState } from 'shared/contexts';
import { useBrandSearch } from 'shared/queries/brand-search';
import { DivisionEnum, ERPEnum, IBrand } from 'shared/types';
import { getDivisionByPermission, getERPByPermission } from 'shared/utility';

interface ISalesRepNameProps {
    name: `${string}` | `${string}.${string}` | `${string}.${number}`;
    businessUnit: string;
}

export function BrandDropdown({ name = 'brand', businessUnit }: ISalesRepNameProps) {
    const user = useUser();
    const { divisionEnabled, erpEnabled } = usePermissionERPDivisionState();

    const isRadius = getERPByPermission(erpEnabled) === ERPEnum.RADIUS;
    const isWandS = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.WANDS);
    const isFandB = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.FANDB);
    const isRequired = !!isRadius && !!isWandS;
    const { watch, setValue } = useFormContext();
    const {
        field: { ref, value, onChange },
        fieldState: { error },
    } = useController({
        name,
        rules: {
            required: {
                message: 'Please select a Brand to continue',
                value: isRequired,
            },
        },
    });

    const search = (watch(name) as unknown) as string;
    const debouncedSearch = useDebounce(search, 300);
    const { data, isLoading, isFetching } = useBrandSearch(
        debouncedSearch,
        businessUnit,
        user,
        isRadius,
        isFandB,
        isWandS
    );
    const loading = isLoading || isFetching;

    const onSelect = useCallback(
        (brand: string | IBrand | null) => {
            if (typeof brand === 'string') {
                setValue('brand', '');
            } else if (brand && brand.inputValue) {
            } else {
                setValue('brand', brand?.displayname || '');
            }
        },
        [setValue]
    );

    const onClear = useCallback(() => {
        setValue('brand', '');
    }, [setValue]);

    return (
        <Autocomplete
            ref={ref}
            value={value}
            id={name}
            onChange={async (_, value, reason) => {
                onSelect(value);
                if (reason === 'clear') {
                    onClear();
                }
            }}
            onInputChange={(_, value, reason) => {
                if (reason === 'clear') {
                    onClear();
                }
                onChange(value);
            }}
            options={data.sort((a, b) => a.displayname.localeCompare(b.displayname)) || []}
            selectOnFocus
            freeSolo
            isOptionEqualToValue={(option, value) =>
                option.displayname === value.displayname || option.displayname === value.inputValue
            }
            getOptionLabel={option => (option.displayname || option.inputValue || option).toUpperCase()}
            renderOption={(props, option) => (
                <li {...props} key={option.uniqueid}>
                    {option.displayname.toUpperCase()}
                </li>
            )}
            data-testid={`${name}-combobox`}
            renderInput={params => (
                <TextField
                    variant='outlined'
                    {...params}
                    label={`Brand ${isRequired ? '(Required)' : '(Optional)'}`}
                    error={Boolean(error)}
                    helperText={error?.message}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: loading ? (
                            <CircularProgress color='inherit' size={20} />
                        ) : (
                            params.InputProps.endAdornment
                        ),
                    }}
                    InputLabelProps={{ shrink: true }}
                />
            )}
        />
    );
}
